@import "../../variable.modules.css";

.documentComponentWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 200px;
    background-color: var(--box-bg-primary);
    border-radius: 15px;
    margin-left: 20px;
    margin-top: 20px;
    box-shadow: var(--list-wrapper-box-shadow);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.documentComponentWrapperFocus {
    border: 3px solid var(--ui-scheme-color);
    box-sizing: border-box;
}

.documenComponentContainer {
    position: relative;
    width: 95%;
    height: 95%;
    display: grid;
    grid-template-rows: 85% 15%;
    grid-template-columns: 100%;
    grid-template-areas:
        "imageContainer"
        "footerContainer";
    /* border: 1px solid black; */


}

.imageContainer {
    position: relative;
    grid-area: imageContainer;
    width: 100%;
    height: 100%;
    border: 1px solid var(--box-bg-tertiary-drk);
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: var(--box-bg-tertiary); */
    border-radius: 15px;
    overflow: hidden;
}

.imageContainer img {
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    border-radius: 10px 10px 10px 10px;
}

.footerContainer {
    position: relative;
    grid-area: footerContainer;
    width: 100%;
    height: 100%;
    /* border: 1px solid orange; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.titleDiv {
    position: relative;
    width: 80%;
    height: 100%;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    overflow: hidden;
}

.titleText {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: var(--bolder-font);
    color: var(--primary-font-color);
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5%;
}

.favBtnContainer {
    position: relative;
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
    /* background-color: blueviolet; */
}

.favBtnDiv {
    height: 80%;
    aspect-ratio: 1/1;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

    .documentComponentWrapper {
        position: relative;
        width: 100%;
        height: 200px;
        margin: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 19px;
    }

    .documenComponentContainer {
        position: relative;
        width: 92%;
        height: 92%;
        display: grid;
        grid-template-rows: 85% 15%;
        grid-template-columns: 100%;
        grid-template-areas:
            "imageContainer"
            "footerContainer";
        justify-content: center;
        align-items: center;
        /* border: 1px solid black; */
    }

    .imageContainer {
        position: relative;
        grid-area: imageContainer;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: center;
        align-items: center;
        /* border-radius: 20px; */
    }

    .footerContainer {
        position: relative;
        grid-area: footerContainer;
        width: 100%;
        height: 100%;
        /* border: 1px solid orange; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .titleDiv {
        position: relative;
        width: 80%;
        height: 100%;
        /* border: 1px solid blue; */
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        overflow: hidden;
    }

    .titleText {
        width: 90%;
        margin-left: 5%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.8rem;
        font-weight: var(--bolder-font);
    }


    .favBtnContainer {
        position: relative;
        width: 20%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        /* border: 1px solid black; */
        /* background-color: blueviolet; */
    }

}