.addBtnWrapper {
    width: 5rem;
    height: 2.5rem;
    width: 6rem;
    height: 3rem;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 65;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0.23rem;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    scroll-behavior: smooth;
    border-radius: 0.8rem;
  cursor: pointer;

}

.addBtnWrapperShrink {
    width: 2.5rem;
    height: 2.5rem;
    /* aspect-ratio: 1/1; */
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    scroll-behavior: smooth;
}

.iconDiv {
    height: 100%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    background-color: #ADD2FF;
    border-radius: 0.65rem;
}

.plusIcon {
    font-size: 1.6rem;
}

.addTextDiv {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

.addTextDivHide {
    display: none;
}

.addTextDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 1.1rem;
    font-weight: var(--bolder-font);
    color: #002A9A;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    scroll-behavior: smooth;
}