.mainComponent {
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
}

.homeComponent {
  position: relative;
  width: 100%;
  height: 100%;
  /* border: 1px solid orange; */
  display: grid;
  grid-template-columns: minmax(0, 0.25fr) minmax(0, 1fr);
  grid-template-rows: 15% 10% 75%;
  grid-template-areas:
    "sideBarSection headerBar"
    "sideBarSection searchTitleSection"
    "sideBarSection contentDisplay";
  align-items: center;
  overflow: hidden;
}

.homeComponent {
  position: relative;
  width: 100%;
  height: 100%;
  /* border: 1px solid orange; */
  display: grid;
  grid-template-columns: minmax(0, 0.25fr) minmax(0, 1fr);
  grid-template-rows: 15% 10% 75%;
  grid-template-areas:
    "sideBarSection headerBar"
    "sideBarSection searchTitleSection"
    "sideBarSection contentDisplay";
  align-items: center;
  overflow: hidden;
}

.homeComponentForSettings {
  position: relative;
  width: 100%;
  height: 100%;
  /* border: 1px solid orange; */
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  grid-template-areas:
    "SetttingsComponent";
  align-items: center;
  overflow: hidden;
}


.sideBarSection {
  grid-area: sideBarSection;
  /* border: 1px solid black; */
  width: 100%;
  height: 100%;
}





@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .homeComponent {
    position: relative;
    width: 100%;
    height: 100%;
    /* border: 1px solid orange; */
    display: grid;
    grid-template-columns: 100%;
    /* grid-template-rows: minmax(0, 0.5fr) minmax(0, 0.3fr) minmax(0, 3fr) minmax(0, 0.5fr); */
    grid-template-rows: 15% 5% 68% 12%;
    /* grid-template-rows: 15% 5% 60% 10%; */
    grid-template-areas:
      "headerBar headerBar"
      "searchTitleSection searchTitleSection"
      "contentDisplay contentDisplay"
      "tabBarSection tabBarSection";
    align-items: center;
    overflow: hidden;
  }

  .sideBarSection {
    display: none;
    grid-area: sideBarSection;
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
  }

  .tabBarSection {
    grid-area: tabBarSection;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
  }
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {

  .homeComponent {
    position: relative;
    width: 100%;
    height: 100%;
    /* border: 1px solid orange; */
    display: grid;
    grid-template-columns: minmax(0, 0.25fr) minmax(0, 1fr);
    grid-template-columns: 10% 90%;
    grid-template-rows: 15% 10% 75%;
    grid-template-areas:
      "sideBarSection headerBar"
      "sideBarSection searchTitleSection"
      "sideBarSection contentDisplay";
    align-items: center;
    overflow: hidden;
  }

}