@import "../../../variable.modules.css";

.fieldWrapper {
    position: relative;
    width: 78%;
    height: 60px;
    background-color: var(--box-bg-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin-top: 8px;
    box-shadow: var(--dashboard-activity-field-shadow);
}

.fieldContainer {
    position: relative;
    width: 90%;
    height: 90%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
        "upperPartition"
        "lowerPartition";
    /* border: 1px solid orange; */
}

.upperPartition {
    position: relative;
    grid-area: upperPartition;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    grid-template-rows: 100%;
    grid-template-areas:
        "fieldNameLabelWrapper oldValueWrapper";
}

.fieldNameLabelWrapper {
    grid-area: fieldNameLabelWrapper;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid skyblue; */
    padding-left: 0.5rem;
    padding-right: 0.2rem;
}

.fieldNameLabelWrapper p {
    font-family: "Poppins", sans-serif;
    font-size: 0.75rem;
    font-weight: var(--bolder-font);
    color: var(--primary-font-color);
    margin: 0px;

}

.oldValueWrapper {
    grid-area: oldValueWrapper;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* border: 0.03rem solid palevioletred; */
    padding-right: 0.2rem;
    box-sizing: border-box;
}

.oldValueWrapper p {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--secondary-font-color);
    margin: 0px;
    padding: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: var(--bolder-font);
}

.lowerPartition {
    position: relative;
    grid-area: lowerPartition;
    width: 100%;
    height: 100%;
    /* border: 1px solid blue; */
    display: grid;
    grid-template-columns: 10% auto minmax(0, 1fr);
    grid-template-rows: 100%;
    grid-template-areas:
        "arrowElementWrapper changedToLableWrapper newValueWrapper";
}

.arrowElementWrapper {
    position: relative;
    grid-area: arrowElementWrapper;
    width: 100%;
    height: 100%;
    /* border: 0.03rem solid white; */
}

.arrowElementWrapper::after {
    position: absolute;
    right: 0%;
    top: 50%;
    content: "";
    background-color: var(--ui-scheme-color);
    width: 50%;
    height: 0.05rem;
}

.arrowElementWrapper::before {
    position: absolute;
    top: 0px;
    left: 50%;
    content: "";
    background-color: var(--ui-scheme-color);
    width: 0.05rem;
    height: 50%;
}

.changedToLableWrapper {
    grid-area: changedToLableWrapper;
    width: 100%;
    height: 100%;
    /* border: 0.03rem solid palegreen; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0.3rem;
    padding-right: 0.2rem;
}

.changedToLableWrapper p {
    font-family: "Poppins", sans-serif;
    font-size: 0.75rem;
    font-weight: var(--bolder-font);
    color: var(--primary-font-color);
    margin: 0px;
    padding: 0px;
    font-weight: var(--bolder-font);
}

.newValueWrapper {
    grid-area: newValueWrapper;
    width: 100%;
    height: 100%;
    /* border: 0.03rem solid orange; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0.3rem;
    padding-right: 0.2rem;
    box-sizing: border-box;
}

.newValueWrapper p {
    font-family: "Poppins", sans-serif;
    font-size: 0.75rem;
    font-weight: var(--bolder-font);
    color: var(--ui-scheme-color);
    margin: 0px;
    padding: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: var(--bolder-font);
}



@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

    .fieldWrapper {
        border-radius: clamp(0.8rem, 0.40000000000000013rem + 1.9999999999999998vw, 1.0rem) !important;
    }

    .fieldNameLabelWrapper p {
        /* font-size: 0.65rem; */
        /* font-size: 0.85rem; */
        font-size: clamp(0.65rem, 0.2500000000000001rem + 1.9999999999999998vw, 0.85rem) !important;
    }

    .oldValueWrapper p {
        /* font-size: 0.65rem; */
        /* font-size: 0.85rem; */
        font-size: clamp(0.65rem, 0.2500000000000001rem + 1.9999999999999998vw, 0.85rem) !important;
    }

    .changedToLableWrapper p {
        /* font-size: 0.65rem; */
        /* font-size: 0.85rem; */
        font-size: clamp(0.65rem, 0.2500000000000001rem + 1.9999999999999998vw, 0.85rem) !important;
    }


    .newValueWrapper p {
        /* font-size: 0.65rem; */
        /* font-size: 0.85rem; */
        font-size: clamp(0.65rem, 0.2500000000000001rem + 1.9999999999999998vw, 0.85rem) !important;
    }

    .fieldWrapper::before {
        position: absolute;
        left: 0px;
        content: "";
        background-color: var(--ui-scheme-color);
        width: 0.15rem;
        height: 40%;
        border-radius: 10px;
    }
}