/* .contentDisplay {
  position: relative;
  width: 100%;
  height: 100%;
  grid-row-start: 4;
  grid-row-end: 5;
  display: flex;
  justify-content: flex-start;
  justify-content: center;
  align-items: center;
} */
.contentDisplay {
  position: relative;
  grid-area: contentDisplay;
  /* border: 1px solid green; */
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  justify-content: center;
  align-items: center;
}

.lazySuspenseFallBackDiv {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
}