@import "./variable.modules.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

body::-webkit-scrollbar {
  display: none;
} */

.app {
  position: relative;
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(180deg, #DFEDF4 33.14%, #E8F0EC 51.89%, #F7F9F6 76.37%); */
  background: var(--app-main-bg);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* .logoPage {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #141416;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.lottieWrapper {
  /* border: 1px solid white; */
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1c1f2c;
  width: clamp(4rem, -2rem + 30vw, 7rem) !important;
  border-radius: clamp(
    1.2rem,
    -1.4000000000000001rem + 13vw,
    2.5rem
  ) !important;
}

.app::-webkit-scrollbar {
  display: none;
}
