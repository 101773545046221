@import "../../variable.modules.css";

.cardWrapper {
    /* display: flex; */
    display: none;
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--box-bg-primary);
    padding-left: 5%;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cardWrapperClose {
    /* display: flex; */
    display: none;
    width: 0%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--box-bg-primary);
    padding-left: 0%;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    scroll-behavior: smooth;

}

.cardContainer {
    width: 70%;
    height: 90%;
    /* border: 1px solid red;

    display: grid;
    grid-template-columns: 10% 90%;
    grid-template-rows: 15% 20% 15% 10% 20% 20%;
    grid-template-rows: 10% 20% 15% 15% 20% 20%;

    grid-template-areas:
        "cardHeader cardHeader"
        "logoTitleWrapper logoTitleWrapper"
        "categoryWrapper categoryWrapper"
        "cardHolderWrapper cardHolderWrapper"
        "cardNumberWrapper cardNumberWrapper"
        "dateOfBirthWrapper expiryIssueWrapper";
    box-sizing: border-box;
    */
    display: grid;
    grid-template-columns: 25% minmax(0, 1fr);
    grid-template-rows: 10% 17% 14% 14% 22% 22%;
    grid-template-rows: 10% 18% 14% 14% 22% 22%;
    grid-template-rows: 10% 20% 15% 55%;
    grid-template-areas:
        "cardHeader cardHeader"
        "logoTitleWrapper logoTitleWrapper"
        "categoryWrapper categoryWrapper"
        "subcardWrapper subcardWrapper";
    padding-bottom: 0%;
    padding-top: 0%;
    box-sizing: border-box;
    /* border: 1px solid red; */
}

.cardHeader {
    grid-area: cardHeader;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid orange; */
}

.backBtnContainer {
    width: 50%;
    height: 100%;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0%;
    padding-top: 0%;
}

.backBtnDiv {
    width: auto;
    height: 22px;
    height: 25px;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0%;
    padding-right: 5%;
    border-radius: 5px;
    margin-right: 10px;
    /* transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); */
    cursor: pointer;
}


.curdBtnContainer {
    width: 50%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.editBtnDiv {
    width: auto;
    height: 22px;
    height: 25px;
    /* border: 1px solid black; */
    background-color: #9DC1FA;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 5px;
    margin-right: 10px;
    /* transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    cursor: pointer;

}

.editBtnDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: var(--bolder-font);
    color: #002A9A;
}

.saveBtnDiv {
    width: auto;
    height: 22px;
    height: 25px;

    /* border: 1px solid black; */
    background-color: #58BF6F;
    /* background-color: white; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 5px;
    margin-right: 10px;
    /* transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    cursor: pointer;

}

.saveBtnDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 11px;
    font-weight: 400;
    color: white;
}

.cancelBtnDiv {
    width: auto;
    height: 22px;
    height: 25px;

    /* border: 1px solid black; */
    background-color: #B4B3BA;

    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 5px;
    margin-right: 10px;
    /* transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    cursor: pointer;


}

.cancelBtnDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 11px;
    font-weight: 450;
    color: white;

}

.deleteBtnDiv {
    width: auto;
    height: 25px;
    /* border: 1px solid black; */
    background-color: #D64339;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 5px;
    margin-right: 10px;
    /* transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    cursor: pointer;

}

.spinner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid orange; */
}

.deleteBtnDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 11px;
    font-weight: 400;
    color: white;
}

.favBtnDiv {
    height: 60%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
    cursor: pointer;
}

.crudIcons {
    margin-right: 5px;
}


.logoTitleWrapper {
    grid-area: logoTitleWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* border: 1px solid blue; */
    padding-left: 0%;
}

.logoTitleContainer {
    width: 100%;
    height: 85%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0%;

}

.logoContainer {
    /* width: auto; */
    /* width: 30%; */
    height: 100%;
    aspect-ratio: 1/1;
    /* border: 1px solid orange; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* border: 1px solid green; */
    margin-right: 2%;
}


.logoDiv {
    /* width: 80%; */
    aspect-ratio: 1/1;
    height: 100%;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--box-bg-tertiary);
    background-color: var(--logo-div-bg);
}

.logoDivActive {
    border: 3px solid #6f9bd0b5;
}

.logoIcon {
    width: 100%;
    height: 100%;
}

.titleContainer {
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 5%;
    /* border: 1px solid red; */
    border-radius: 0.8rem;
}

.titleLabelDiv {
    width: 80%;
    height: 80%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

}

.titleLabelDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 0.85rem;
    font-weight: var(--bolder-font);
    color: var(--secondary-font-color);
}

.titleInputDiv {
    width: 95%;
    height: 80%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

}

.titleInputActive {
    width: 90%;
    height: 100%;
    border-bottom: none;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    background: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 1.2rem;
    font-weight: var(--bold-font);
    color: var(--primary-font-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.titleInputNotActive {
    width: 90%;
    height: 100%;
    border-bottom: none;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    background: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 1.2rem;
    font-weight: var(--bold-font);
    color: var(--primary-font-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.categoryWrapper {
    grid-area: categoryWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid rgb(182, 69, 88); */
    padding-left: 0%;

}

.categoryContainer {
    width: 100%;
    height: 60%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--box-bg-tertiary);
    border-radius: 10px;

}

.categoryLabelDiv {
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5%;
}

.categoryLabelDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 1.2rem;
    font-weight: var(--bolder-font);
    color: var(--primary-font-color);
}

.catergoryInputDiv {
    position: relative;
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* border: 1px solid black; */
}

.categoryInput {
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem;
    font-weight: var(--bold-font);
    color: var(--ui-scheme-color);
    background-color: var(--category-box-bg);
    border: none;
    outline: none !important;
    height: 75%;
    width: 80%;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}



.popUpBtnIconDiv {
    width: 15%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.popUpIcon {
    font-size: 20px;
    color: var(--primary-font-color);
    display: none;
    cursor: pointer;
}

.categoryInput:focus {
    /* border: 1px solid #0475ff84; */
}

.inputPopUpDiv {
    top: 105%;
    position: absolute;
    width: 150px;
    height: 200px;
    background-color: white;
    border: 1px solid rgba(128, 128, 128, 0.363);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 10%;
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.inputPopUpText {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: black;
    margin: 5px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.inputPopUpText:hover {
    /* background-color: red; */
    width: 80%;
}

.subCardWrapper {
    grid-area: subcardWrapper;
    display: grid;
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
}

.focusFieldStyle {
    border: 2px solid var(--ui-scheme-color);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

    .cardWrapper {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        padding-left: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-top: 0%;
        margin-bottom: 0.2%;
        transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    .cardWrapperClose {
        display: flex;
        width: 100%;
        height: 0%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 0%;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        overflow: hidden;
        padding: 0px;
        margin: 0px;
        transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);

    }

    .cardContainer {
        /* border: 1px solid black; */
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 25% minmax(0, 1fr);
        grid-template-rows: 10% 17% 14% 14% 22% 22%;
        grid-template-rows: 10% 18% 14% 14% 22% 22%;
        grid-template-rows: 10% 20% 15% 55%;
        grid-template-areas:
            "cardHeader cardHeader"
            "logoTitleWrapper logoTitleWrapper"
            "categoryWrapper categoryWrapper"
            "subcardWrapper subcardWrapper";
        padding-bottom: 0%;
        padding-top: 0%;
        box-sizing: border-box;

    }

    .cardHeader {
        grid-area: cardHeader;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid orange; */
    }

    .backBtnContainer {
        width: 30%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 5%;
        padding-top: 0%;
    }

    .backBtnDiv {
        width: 80%;
        height: 60%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }


    /* -------------------------- */

    .curdBtnContainer {
        width: 70%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 5%;
    }

    .editBtnDiv {
        height: 55%;
    }



    .saveBtnDiv {
        height: 55%;
    }



    .cancelBtnDiv {
        height: 55%;
    }



    .deleteBtnDiv {
        height: 55%;
    }

    /* --------------------------------------- */
    .logoTitleWrapper {
        grid-area: logoTitleWrapper;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid blue; */
        padding-left: 0%;
    }

    .logoTitleContainer {
        width: 90%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .logoContainer {
        /* width: auto; */
        /* width: 30%; */
        height: 100%;
        aspect-ratio: 1/1;
        /* border: 1px solid orange; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
        /* border: 1px solid green; */
        margin-right: 2%;
    }


    .logoDiv {
        /* width: 80%; */
        aspect-ratio: 1/1;
        height: 90%;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logoDivActive {
        /* width: 80%; */
        aspect-ratio: 1/1;
        height: 90%;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px solid #a8ccf9b5;
    }

    .logoIcon {
        width: 100%;
        height: 100%;
    }

    .titleContainer {
        width: 70%;
        height: 100%;
        /* border: 1px solid red; */
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding-left: 5%;
    }

    .titleLabelDiv {
        width: 80%;
        height: 80%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    }



    .titleInputDiv {
        width: 100%;
        height: 80%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .categoryWrapper {
        grid-area: categoryWrapper;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid rgb(182, 69, 88); */
        padding-left: 0%;

    }

    .categoryContainer {
        width: 90%;
        height: 60%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: clamp(0.5rem, 0.0rem + 2.5vw, 0.75rem) !important;
    }

    .categoryLabelDiv {
        width: 40%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 5%;
    }



    .catergoryInputDiv {
        position: relative;
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .categoryInput {
        outline: none !important;
        height: 80%;
        width: 85%;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        /* border: 1px solid black; */
    }

    .popUpBtnIconDiv {
        width: 15%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .popUpIcon {
        font-size: 20px;
    }

    .categoryInput:focus {
        /* border: 1px solid #0475ff84; */
    }

    .inputPopUpDiv {
        top: 110%;
        right: 25%;
        position: absolute;
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 5%;
        padding-bottom: 5%;
        padding-left: 8%;
        padding-right: 25%;
        border-radius: 5px;

    }

    .inputPopUpText {
        font-size: 0.8rem;
        margin: 5% 0px 0px 0px;
        font-size: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;
    }

    .inputPopUpText:hover {
        width: 80%;
    }

    .subCardWrapper {
        grid-area: subcardWrapper;
        display: grid;
        width: 100%;
        height: 100%;
        /* border: 1px solid red; */
    }


    /*>  font size */
    .editBtnDiv p {
        font-size: 0.75rem;
        font-size: clamp(0.65rem, 0.2500000000000001rem + 1.9999999999999998vw, 0.85rem) !important;

    }

    .saveBtnDiv p {
        font-size: 0.75rem;
        font-size: clamp(0.65rem, 0.2500000000000001rem + 1.9999999999999998vw, 0.85rem) !important;

    }


    .cancelBtnDiv p {
        font-size: 0.75rem;
        font-size: clamp(0.65rem, 0.2500000000000001rem + 1.9999999999999998vw, 0.85rem) !important;

    }


    .deleteBtnDiv p {
        font-size: 0.75rem;
        font-size: clamp(0.65rem, 0.2500000000000001rem + 1.9999999999999998vw, 0.85rem) !important;
    }

    .crudIcons {
        margin-right: 0.3rem;
        font-size: clamp(0.8rem, 2.220446049250313e-16rem + 3.9999999999999996vw, 1.2rem) !important;

    }

    .titleLabelDiv p {
        font-size: 15px;
        font-size: 0.85rem;
        font-size: clamp(0.7rem, 0.09999999999999987rem + 3.0000000000000004vw, 1.0rem) !important;
    }


    .titleInputActive {
        font-size: 1.5rem;
        font-size: clamp(1.1rem, 0.30000000000000027rem + 3.9999999999999996vw, 1.5rem) !important;

    }

    .titleInputNotActive {
        font-size: 1.5rem;
        font-size: clamp(1.1rem, 0.30000000000000027rem + 3.9999999999999996vw, 1.5rem) !important;
    }


    .categoryLabelDiv p {
        font-size: 1.2rem;
        font-size: clamp(1.0rem, 0.0rem + 5.0vw, 1.5rem) !important;
    }

    .categoryInput {
        font-size: 1.1rem;
        font-size: clamp(0.85rem, -0.15000000000000024rem + 5.000000000000001vw, 1.35rem) !important;
    }

}



@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {

    .cardWrapper {
        display: none;
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: var(--box-bg-primary);
        padding-left: 5%;
        webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        overflow: hidden;
        /* border: 1px solid red; */
        padding-top: 5%;
    }

    .cardWrapperClose {
        display: none;
        width: 0%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: var(--box-bg-primary);
        padding-left: 0%;
        webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        overflow: hidden;
        transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
        scroll-behavior: smooth;
    }

    .cardContainer {
        width: 93%;
        height: 50%;
        display: grid;
        grid-template-columns: 25% minmax(0, 1fr);
        grid-template-rows: 10% 17% 14% 14% 22% 22%;
        grid-template-rows: 10% 18% 14% 14% 22% 22%;
        grid-template-rows: 10% 20% 15% 55%;
        grid-template-areas:
            "cardHeader cardHeader"
            "logoTitleWrapper logoTitleWrapper"
            "categoryWrapper categoryWrapper"
            "subcardWrapper subcardWrapper";
        padding-bottom: 0%;
        padding-top: 0%;
        box-sizing: border-box;
        /* border: 1px solid blue; */








    }
}