@import "../../variable.modules.css";

.searchListWrapper {
  position: absolute;
  width: 100%;
  height: 98%;
  margin-top: 0.5%;
  z-index: 800;
  background: var(--app-main-bg);
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.contentContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  scroll-behavior: smooth;
  overflow-y: scroll;
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  scrollbar-width: none;
  /* firefox hide scrollbar  */
  -ms-overflow-style: none;
  /* firefox hide scrollbar  */

}

.contentContainer::-webkit-scrollbar {
  background-color: rgba(176, 32, 32, 0);
  height: 4px;
  width: 3px;
}

.contentContainer::-webkit-scrollbar-track {
  background-color: rgb(231, 228, 228);
  background-color: rgba(231, 228, 228, 0.062);
  width: 2px;
}

.contentContainer::-webkit-scrollbar-thumb {
  background-color: var(--ui-scheme-color);
  border-radius: 10px;
}


@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .contentContainer {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    scroll-behavior: smooth;
    overflow-y: scroll;
    padding-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 20px;
    box-sizing: border-box;
    scrollbar-width: none;
    /* firefox hide scrollbar  */
    -ms-overflow-style: none;
    /* firefox hide scrollbar  */
    /* border: 1px solid black; */
  }

}