.subCardWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 33% 33% 33%;
    grid-template-areas:
        "cardHolderWrapper cardHolderWrapper"
        "cardNumberWrapper cardNumberWrapper"
        "expiryDateWrapper cvvNumberWrapper";
    padding-bottom: 0%;
    padding-top: 0%;
    box-sizing: border-box;
    justify-items: center;
}

/* 
> We are using input instead of div for textField
> because div will expand when using white-space:nowrap
> but using input(readOnly) gives desired result
*/

.textInputCommonStyle {
    border: none;
    outline: none;
    background: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Poppins", sans-serif;
    color: var(--primary-font-color);
    font-weight: var(--bold-font);
}


/* _____________________________________________ */

.cardHolderWrapper {
    grid-area: cardHolderWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid orange; */
}


.cardHolderContainer {
    height: 70%;
    /* border: 1px solid black; */
    display: grid;
    align-items: center;
    grid-template-columns: 20% 60% 20%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
        "cardHolderIconDiv cardHolderLabelTextDiv cardVenderIconContainer  "
        "cardHolderIconDiv cardHolderInputDiv cardVenderIconContainer";
    background-color: var(--box-bg-tertiary);
    border-radius: 10px;
}

.cardHolderIconDiv {
    grid-area: cardHolderIconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardHolderIcon {
    font-size: 1.8rem;
    color: var(--field-icon-color);
}

.cardHolderLabelTextDiv {
    grid-area: cardHolderLabelTextDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-items: center;
    align-items: center;
}

.cardHolderLabelTextDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--secondary-font-color);
}

.cardHolderInputDiv {
    grid-area: cardHolderInputDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    /* border: 1px solid salmon; */
}

.cardHolderTextField {
    width: 100%;
    height: 100%;
    /* border: 1px solid skyblue; */
}

.cardHolderTextFieldFont {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bold-font);
    color: var(--primary-font-color);
}


/* _____________________________________________________ */
.cardVenderIconContainer {
    grid-area: cardVenderIconContainer;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cardVenderIconDiv {
    aspect-ratio: 1/1;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.cardVenderLogo {
    /* height: 70%; */
    width: 80%;
    /* border: 1px solid black; */
}

/* _____________________________________________ */
.cardNumberWrapper {
    grid-area: cardNumberWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid green; */
}

.cardNumberContainer {
    height: 70%;
    /* border: 1px solid black; */
    display: grid;
    align-items: center;

    grid-template-columns: 20% 60% 20%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
        "cardNumberIconDiv cardNumberLabelTextDiv cardVenderIconContainer  "
        "cardNumberIconDiv cardNumberInputDiv cardVenderIconContainer";
    background-color: var(--box-bg-tertiary);
    border-radius: 10px;
}


.cardNumberIconDiv {
    grid-area: cardNumberIconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardNumIcon {
    font-size: 1.5rem;
    color: var(--field-icon-color);

}

.cardNumberLabelTextDiv {
    grid-area: cardNumberLabelTextDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-items: center;
    align-items: center;
}

.cardNumberLabelTextDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--secondary-font-color);
}

.cardNumberInputDiv {
    grid-area: cardNumberInputDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
}

.cardNumberTextField {
    width: 100%;
    height: 100%;
    /* border: 1px solid skyblue; */

}

.cardNumberTextFieldFont {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--primary-font-color);
}



/* _____________________________________________ */

.expiryDateWrapper {
    grid-area: expiryDateWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: flex-start;
}

.expiryDateContainer {
    height: 70%;
    width: 90%;
    /* border: 1px solid black; */
    display: grid;
    align-items: center;
    grid-template-columns: 30% 70%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
        "expiryIconDiv expiryLabelTextDiv"
        "expiryIconDiv expiryInputDiv";
    background-color: var(--box-bg-tertiary);
    border-radius: 10px;
}

.expiryIconDiv {
    grid-area: expiryIconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.expiryDateIcon {
    font-size: 1.5rem;
    color: var(--field-icon-color);
}


.expiryLabelTextDiv {
    grid-area: expiryLabelTextDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.expiryLabelTextDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--secondary-font-color);
}

.expiryTextField {
    width: 100%;
    height: 100%;
    /* border: 1px solid skyblue; */
}

.expiryInputDiv {
    grid-area: expiryInputDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.expiryTextFieldFont {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--primary-font-color);

}

/* _____________________________________________ */

.cvvNumberWrapper {
    grid-area: cvvNumberWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: flex-end;
}

.cvvNumberContainer {
    height: 70%;
    width: 90%;
    /* border: 1px solid black; */
    display: grid;
    align-items: center;
    grid-template-columns: 30% 70%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
        "cvvNumberIconDiv cvvNumberLabelTextDiv"
        "cvvNumberIconDiv cvvNumberInputDiv";
    background-color: var(--box-bg-tertiary);
    border-radius: 10px;
}

.cvvNumberIconDiv {
    grid-area: cvvNumberIconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.cvvIcon {
    font-size: 1.5rem;
    color: var(--field-icon-color);
}

.cvvNumberLabelTextDiv {
    grid-area: cvvNumberLabelTextDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cvvNumberLabelTextDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--secondary-font-color);
}

.cvvNumberInputDiv {
    grid-area: cvvNumberInputDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cvvTextField {
    width: 100%;
    height: 100%;
    /* border: 1px solid skyblue; */
}

.cvvTextFieldFont {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--primary-font-color);
}


@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

    .subCardWrapper {
        position: relative;
        /* border: 1px solid red; */
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 33% 33% 33%;
        grid-template-areas:
            "cardHolderWrapper cardHolderWrapper"
            "cardNumberWrapper cardNumberWrapper"
            "expiryDateWrapper cvvNumberWrapper";
        padding-bottom: 0%;
        padding-top: 0%;
        box-sizing: border-box;
        justify-items: center;
    }

    /* _____________________________________________ */

    .cardHolderWrapper {
        width: 90%;
        height: 100%;
    }

    .cardHolderContainer {
        border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;
    }

    .cardHolderLabelTextDiv p {
        font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important;
    }

    .cardHolderTextFieldFont {
        font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important;
    }

    .cardHolderIcon {
        font-size: clamp(1.6rem, 4.440892098500626e-16rem + 7.999999999999999vw, 2.4rem) !important;
    }

    /* _____________________________________________________ */


    /* _____________________________________________ */
    .cardNumberWrapper {
        width: 90%;
        height: 100%;
    }

    .cardNumberContainer {
        border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;
    }

    .cardNumberLabelTextDiv p {
        font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important;
    }

    .cardNumberTextFieldFont {
        font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important;
    }

    .cardNumIcon {
        font-size: clamp(1.3rem, 0.10000000000000031rem + 5.999999999999998vw, 1.9rem) !important;
    }

    /* _____________________________________________ */

    .expiryDateWrapper {
        padding-left: 10%;
    }

    .expiryDateContainer {
        border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;
    }


    .expiryLabelTextDiv p {
        font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important;
    }

    .expiryTextFieldFont {
        font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important;
    }

    .expiryDateIcon {
        font-size: clamp(1.3rem, 0.10000000000000031rem + 5.999999999999998vw, 1.9rem) !important;
    }


    /* _____________________________________________ */

    .cvvNumberWrapper {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .cvvNumberContainer {
        border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;
    }

    .cvvNumberLabelTextDiv p {
        font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important;
    }

    .cvvTextFieldFont {
        font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important;
    }

    .cvvIcon {
        font-size: clamp(1.3rem, 0.10000000000000031rem + 5.999999999999998vw, 1.9rem) !important;
    }
}