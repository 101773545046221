@import "../../variable.modules.css";

.dayList {
    width: 95%;
    height: 95%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 20% 80%;
    grid-template-areas:
        "weekDaysContainer"
        "dayListContainer";
    /* border: 1px solid black; */
}

.weekDaysContainer {
    grid-area: weekDaysContainer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */

}

.weekDayDiv {
    width: 14.1%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

.weekDayDiv p {
    font-size: 0.7rem;
    font-weight: var(--boldest-font);
    color: var(--ui-scheme-color);
}

.satSunFont {
    /* color: #D64339 !important; */
}

.dayListContainer {
    grid-area: dayListContainer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    /* border: 1px solid black; */
}

.dateDiv {
    width: 14.1%;
    height: 16%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

.dateInnerDiv {
    width: 70%;
    aspect-ratio: 1/1;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #00299a0c; */

}

.dateDiv p {
    font-size: 0.7rem;
    font-weight: var(--boldest-font);
    color: var(--primary-font-color);
    cursor: pointer;
}

.selectedDivStyle {
    background-color: var(--ui-scheme-color);
    border-radius: 0.3rem;
    border-radius: 50%;
    aspect-ratio: 1/1;
    height: unset;
    /* background-color: #00299ab5; */
}

.selectedDivStyle p {
    color: var(--listCount-font-color);
    font-weight: var(--bolder-font);
}


@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

    .weekDayDiv p {
        font-size: clamp(0.6rem, -1.1102230246251565e-16rem + 3.0000000000000004vw, 0.9rem) !important;
        font-weight: var(--boldest-font);
    }

    .dateDiv p {
        font-size: clamp(0.6rem, -0.09999999999999998rem + 3.4999999999999996vw, 0.95rem) !important;
        font-weight: var(--boldest-font);
    }
}