@import "../../../variable.modules.css";

.cardWrapper {
  position: relative;
  /* display: flex; */
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--box-bg-primary);
  padding-left: 5%;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

.cardWrapperClose {
  /* display: flex; */
  display: none;
  width: 0%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--box-bg-primary);
  padding-left: 0%;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  scroll-behavior: smooth;

}

.cardContainer {
  position: relative;
  width: 70%;
  height: 90%;
  /* border: 1px solid red; */
  /* border: 1px solid black; */
  display: grid;
  grid-template-columns: 10% 90%;
  grid-template-rows: 15% 20% 15% 10% 20% 20%;
  grid-template-rows: 10% 20% 15% 15% 20% 20%;

  grid-template-areas:
    "cardHeader cardHeader"
    "logoTitleWrapper logoTitleWrapper"
    "categoryWrapper categoryWrapper"
    "appWebSiteWrapper appWebSiteWrapper"
    "usernameWrapper usernameWrapper"
    "passwordWrapper passwordWrapper";
  box-sizing: border-box;
}

.cardHeader {
  grid-area: cardHeader;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid orange; */
}

.backBtnContainer {
  width: 50%;
  height: 100%;
  /* border: 1px solid blue; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 0%;
  padding-top: 0%;
}

.backBtnDiv {
  width: auto;
  height: 20px;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0%;
  padding-right: 5%;
  border-radius: 5px;
  margin-right: 10px;
  /* transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); */
  cursor: pointer;

}

.curdBtnContainer {
  width: 50%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.favBtnDiv {
  height: 60%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  cursor: pointer;
}

.spinner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid orange; */
}

.logoTitleWrapper {
  grid-area: logoTitleWrapper;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* border: 1px solid blue; */
  padding-left: 0%;
}

.logoTitleContainer {
  position: relative;
  width: 100%;
  height: 85%;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0%;

}

.logoContainer {
  /* width: auto; */
  /* width: 30%; */
  height: 100%;
  aspect-ratio: 1/1;
  /* border: 1px solid orange; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid green; */
  margin-right: 2%;
}


.logoDiv {
  /* width: 80%; */
  aspect-ratio: 1/1;
  height: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--box-bg-tertiary);
  background-color: var(--logo-div-bg);
}


.logoIcon {
  width: 100%;
  height: 100%;
}

/* 
> We are using input instead of div for textField
> because div will expand when using white-space:nowrap
> but using input(readOnly) gives desired result
*/
.textInputCommonStyle {
  border: none;
  outline: none;
  background: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Poppins", sans-serif;
  color: var(--primary-font-color);
  font-weight: var(--bold-font);
}


.titleContainer {
  position: relative;
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 5%;
  /* border: 1px solid black; */
}

.titleLabelDiv {
  position: relative;
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  /* border: 1px solid black; */
}

.titleLabelDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 0.85rem;
  font-weight: var(--bolder-font);
  color: var(--secondary-font-color);
}

.titleInputDiv {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid skyblue; */
}

.titleTextField {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: start;
  /* border: 1px solid skyblue; */
}

.titleTextFieldFont {
  font-size: 1.2rem;
  font-weight: var(--bold-font);
  color: var(--primary-font-color);
}

.categoryWrapper {
  grid-area: categoryWrapper;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0%;
}

.categoryContainer {
  width: 100%;
  height: 60%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--box-bg-tertiary);
  border-radius: 10px;
}

.categoryLabelDiv {
  width: 50%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5%;
}

.categoryLabelDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: var(--bolder-font);
  color: var(--primary-font-color);
}

.categoryInputDiv {
  position: relative;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
}

.categoryTextField {
  height: 75%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: var(--category-box-bg);
  text-align: center;
}

.categoryTextFieldFont {
  font-size: 0.9rem;
  font-weight: var(--bold-font);
  color: var(--ui-scheme-color);
}

.appWebSiteWrapper {
  grid-area: appWebSiteWrapper;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid purple; */
  padding-left: 0%;
}


.appWebSiteContainer {
  width: 100%;
  height: 60%;
  /* border: 1px solid black; */
  display: flex;
  display: grid;
  grid-template-columns: 20% 40% 40%;
  grid-template-rows: 100%;
  grid-template-areas:
    "appWebSiteIconDiv appWebsiteLabelDiv appWebsiteInputDiv";
  background-color: var(--box-bg-tertiary);
  border-radius: 10px;

}

.appWebSiteIconDiv {
  grid-area: appWebSiteIconDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.websiteIcon {
  font-size: 1.5rem;
  color: var(--field-icon-color);
}

.appWebsiteLabelDiv {
  grid-area: appWebsiteLabelDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}



.appWebsiteLabelDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 0.85rem;
  font-weight: var(--bolder-font);
  color: var(--secondary-font-color);
}

.appWebsiteInputDiv {
  grid-area: appWebsiteInputDiv;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid skyblue; */
}

.appWebsiteTextField {
  width: 100%;
  height: 100%;
  text-align: start;
  /* border: 1px solid salmon; */
}

.appWebsiteTextFieldFont {
  font-size: 0.8rem;
  font-weight: var(--bold-font);
  color: var(--primary-font-color);
}

.usernameWrapper {
  grid-area: usernameWrapper;
  width: 100%;
  height: 100%;
  /* border: 1px solid orange; */
  padding-left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

}

.usernameContainer {
  width: 100%;
  height: 70%;
  /* border: 1px solid black; */
  display: grid;
  align-items: center;
  grid-template-columns: 20% 80%;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    "usernameIconDiv usernameLabelDiv"
    "usernameIconDiv usernameInputDiv";
  background-color: var(--box-bg-tertiary);
  border-radius: 10px;
}

.usernameIconDiv {
  grid-area: usernameIconDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.usernameIcon {
  color: var(--field-icon-color);

}

.usernameLabelDiv {
  grid-area: usernameLabelDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.usernameLabelDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: var(--bolder-font);
  color: var(--secondary-font-color);
}

.usernameInputDiv {
  grid-area: usernameInputDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid salmon; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.usernameTextField {
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
}

.usernameTextFieldFont {
  font-size: 0.8rem;
  font-weight: var(--bold-font);
  color: var(--primary-font-color);
}

.passwordWrapper {
  grid-area: passwordWrapper;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid orange; */
  padding-left: 0%;

}

.passwordContainer {
  width: 100%;
  height: 70%;
  /* border: 1px solid black; */
  display: grid;
  align-items: center;
  grid-template-columns: 20% 80%;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    "passwordIconDiv passwordLabelDiv"
    "passwordIconDiv passwordInputDiv";
  background-color: var(--box-bg-tertiary);
  border-radius: 10px;


}

.passwordIconDiv {
  grid-area: passwordIconDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.passwordIcon {
  color: var(--field-icon-color);
  font-size: 1rem;
}

.passwordLabelDiv {
  grid-area: passwordLabelDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.passwordLabelDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: var(--bolder-font);
  color: var(--secondary-font-color);
}

.passwordInputDiv {
  grid-area: passwordInputDiv;
  width: 100%;
  height: 100%;
}

.passwordTextField {
  width: 100%;
  height: 100%;
}

.passwordTextFieldFont {
  font-size: 0.8rem;
  font-weight: var(--bold-font);
  color: var(--primary-font-color);
}

/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */


@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

  .cardWrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 95%;
    height: 100%;
    padding-left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* border: 1px solid red; */
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    /* margin-bottom: 2px; */
    margin-bottom: 0.1rem;
  }


  .cardWrapperClose {
    display: flex;
    width: 100%;
    height: 0%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0%;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
    transition: none;
    transition: all 10s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    /* border: 1px solid red; */
    opacity: 0;



  }



  .cardContainer {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 25% minmax(0, 1fr);
    grid-template-rows: 10% 17% 14% 14% 22% 22%;
    grid-template-rows: 10% 18% 14% 14% 22% 22%;
    grid-template-rows: 10% 20% 15% 15% 20% 20%;
    grid-template-areas:
      "cardHeader cardHeader"
      "logoTitleWrapper logoTitleWrapper"
      "categoryWrapper categoryWrapper"
      "appWebSiteWrapper appWebSiteWrapper"
      "usernameWrapper usernameWrapper"
      "passwordWrapper passwordWrapper";
    padding-bottom: 0%;
    padding-top: 0%;
    box-sizing: border-box;
    overflow: hidden;

  }

  .cardHeader {
    grid-area: cardHeader;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid orange; */
  }

  .backBtnContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5%;
    padding-top: 0%;
  }

  .backBtnDiv {
    height: 55%;
  }

  .curdBtnContainer {
    width: 70%;
    height: 100%;
    padding-right: 5%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }


  .favBtnDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65%;
    aspect-ratio: 1/1;
    /* border: 1px solid black; */
  }

  .logoTitleWrapper {
    grid-area: logoTitleWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid blue; */
    padding-left: 0%;
  }

  .logoTitleContainer {
    width: 90%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .logoContainer {
    /* width: auto; */
    /* width: 30%; */
    height: 100%;
    aspect-ratio: 1/1;
    /* border: 1px solid orange; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* border: 1px solid green; */
    margin-right: 2%;
  }


  .logoDiv {
    /* width: 80%; */
    aspect-ratio: 1/1;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: clamp(0.9rem, -0.29999999999999993rem + 6.0vw, 1.5rem) !important;
  }

  .logoIcon {
    width: 100%;
    height: 100%;
  }

  .titleContainer {
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 5%;
  }



  .categoryContainer {
    width: 90%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    /* border: 1px solid red; */
    border-radius: clamp(0.5rem, 0.0rem + 2.5vw, 0.75rem) !important;
  }

  .categoryLabelDiv {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5%;
  }


  .categoryInputDiv {
    position: relative;
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
  }

  .categoryTextField {
    width: 70%;
    height: 80%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .popUpBtnIconDiv {
    width: 15%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .categoryInput:focus {
    /* border: 1px solid #0475ff84; */
  }

  .inputPopUpDiv {
    top: 105%;
    position: absolute;
    width: 170px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 5%;
    padding-bottom: 8%;
    padding-left: 10%;
    border-radius: 5px;

  }

  .inputPopUpText {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: var(--bolder-font);
    margin: 5% 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .inputPopUpText:hover {
    background-color: #0073ff;
    width: 80%;
  }

  .appWebSiteWrapper {
    grid-area: appWebSiteWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid purple; */
    padding-left: 0%;
  }


  .appWebSiteContainer {
    width: 90%;
    height: 60%;
    /* border: 1px solid black; */
    border-radius: clamp(0.5rem, 0.0rem + 2.5vw, 0.75rem) !important;
  }

  .usernameWrapper {
    justify-content: center;
    align-items: center;
  }

  .usernameContainer {
    width: 90%;
    height: 70%;
    border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;
  }

  .passwordWrapper {
    justify-content: center;
    align-items: center;
    /* border: 1px solid orange; */
  }

  .passwordContainer {
    width: 90%;
    height: 70%;
    border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;
  }


  /*  FONT SIZE AND ICONS */

  .titleLabelDiv p {
    font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;
  }

  .categoryLabelDiv p {
    font-size: clamp(1.0rem, 0.0rem + 5.0vw, 1.5rem) !important;
  }

  .appWebsiteLabelDiv p {
    font-size: clamp(0.9rem, 0.30000000000000016rem + 2.999999999999999vw, 1.2rem) !important;
  }

  .usernameLabelDiv p {
    font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important;
  }

  .passwordLabelDiv p {
    font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important;
  }

  .titleTextField {
    width: 95%;
  }

  .titleTextFieldFont {
    font-size: clamp(1.0rem, -0.3999999999999999rem + 6.999999999999999vw, 1.7rem) !important;
  }

  .categoryTextFieldFont {
    font-size: clamp(0.8rem, 0.19999999999999996rem + 3.0000000000000004vw, 1.1rem) !important;
  }

  .appWebsiteTextFieldFont {
    font-size: clamp(0.75rem, 0.1499999999999999rem + 3.0000000000000004vw, 1.05rem) !important;
  }

  .usernameTextFieldFont {
    font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important;
  }

  .passwordTextFieldFont {
    font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important;
  }

  .websiteIcon {
    font-size: clamp(1.2rem, -2.220446049250313e-16rem + 6.000000000000001vw, 1.8rem) !important;
  }

  .usernameIcon {
    font-size: clamp(1.5rem, -0.09999999999999964rem + 7.999999999999999vw, 2.3rem) !important;
  }

  .passwordIcon {
    font-size: clamp(1.5rem, -0.09999999999999964rem + 7.999999999999999vw, 2.3rem) !important;
  }







  /* ----------------------------------------------
  * Generated by Animista on 2022-11-28 19:46:20
  * Licensed under FreeBSD License.
  * See http://animista.net/license for more info. 
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */

  /**
  * ----------------------------------------
  * animation slide-in-bottom
  * ----------------------------------------
  */
  @-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
      transform: translateY(1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
      transform: translateY(1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {

  .cardWrapper {
    /* display: flex; */
    display: none;
    width: 60%;
    height: 95%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--box-bg-primary);
    /* background-color: transparent; */
    padding-left: 5%;
    webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* border: 1px solid black; */
    padding-top: 5%;

  }

  .cardWrapperClose {
    /* display: flex; */
    display: none;
    width: 0%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--box-bg-primary);
    padding-left: 0%;
    webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    scroll-behavior: smooth;
    opacity: 0;
  }

  .cardContainer {
    width: 95%;
    height: 50%;
    /* border: 1px solid red; */
    /* border: 1px solid black; */
    display: grid;
    grid-template-columns: 10% 90%;
    grid-template-rows: 15% 20% 15% 10% 20% 20%;
    grid-template-rows: 10% 20% 15% 15% 20% 20%;
    grid-template-areas:
      "cardHeader cardHeader"
      "logoTitleWrapper logoTitleWrapper"
      "categoryWrapper categoryWrapper"
      "appWebSiteWrapper appWebSiteWrapper"
      "usernameWrapper usernameWrapper"
      "passwordWrapper passwordWrapper";
    box-sizing: border-box;
    margin-top: 5%;
    /* background-color: white; */
  }

}