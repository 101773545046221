.formPageWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.formPageWrapper::-webkit-scrollbar {
  display: none;
}

.formWrapper {
  position: relative;
  width: 350px;
  aspect-ratio: 2/3;
  border-radius: 20px;
  background-color: var(--box-bg-primary);
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 12% 12% minmax(0, 1fr) 5%;
  grid-template-areas:
    "formHeaderWrapper formHeaderWrapper"
    "formMessageWrapper formMessageWrapper "
    "formTagContainer formTagContainer"
    "formFooterWrapper formFooterWrapper";
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* ________________________________________________ */
.formHeaderWrapper {
  grid-area: formHeaderWrapper;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid orange; */
}

.formLabelDiv {
  position: relative;
  width: 80%;
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
}

.formLabelDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: var(--boldest-font);
  color: var(--primary-font-color);
}

.formLabelDiv::after {
  position: absolute;
  content: '';
  background-color: var(--ui-scheme-color);
  width: 15%;
  height: 3px;
  border-radius: 20px;
  bottom: 0px;
}

/* ________________________________________________ */

.formMessageWrapper {
  grid-area: formMessageWrapper;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid blue; */
  box-sizing: border-box;
}

.messageDiv {
  width: auto;
  max-width: 85%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 6px;
  padding: 1% 4% 1% 4%;
  /* margin-top: 3%; */
}

.messageDivError {
  background-color: #D64339;
}

.messageDivError p {
  font-family: "Poppins", sans-serif;
  font-size: 10.5px;
  font-weight: 550;
  color: white;
}

.messageDivSuccess {
  background-color: #58BF6F;

}

.messageDivSuccess p {
  font-family: "Poppins", sans-serif;
  font-size: 10.5px;
  font-weight: 550;
  color: white;
}

/* .messageDiv p {} */
.errorMessageIconDiv {
  width: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
  margin-right: 5px;
}

.warningIcon {
  font-size: 15px !;
  color: white;
  padding: 0px;
  margin: 0px;
}

/* ________________________________________________ */

.formTagContainer {
  grid-area: formTagContainer;
  width: 85%;
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 20% 20% 20% 20% 20%;
  grid-template-areas:
    "firstNameWrapper LastNameWrapper"
    "emailAddressWrapper emailAddressWrapper "
    "passwordWrapper passwordWrapper"
    "confirmPasswordWrapper confirmPasswordWrapper"
    "submitBtnWrapper submitBtnWrapper";
  justify-content: center;
  align-items: center;
  /* border: 1px solid green; */
  margin: auto;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.firstNameWrapper {
  grid-area: firstNameWrapper;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
}

.firstNameContainer {
  width: 95%;
  height: 80%;
  background-color: var(--box-bg-tertiary);
  border-radius: 15px;
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    "iconDiv labelDiv"
    "iconDiv inputDiv";
  box-sizing: border-box;

}

.LastNameWrapper {
  grid-area: LastNameWrapper;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */

}

.lastNameContainer {
  width: 95%;
  height: 80%;

  background-color: var(--box-bg-tertiary);
  border-radius: 15px;
  margin-left: 5%;
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    "iconDiv labelDiv"
    "iconDiv inputDiv";
  box-sizing: border-box;

}

.emailAddressWrapper {
  grid-area: emailAddressWrapper;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
}

.emailAddressContainer {
  width: 100%;
  height: 80%;

  background-color: var(--box-bg-tertiary);
  border-radius: 15px;
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    "iconDiv labelDiv"
    "iconDiv inputDiv";
  box-sizing: border-box;

}

.passwordWrapper {
  grid-area: passwordWrapper;
  width: 100%;
  height: 90%;
  /* border: 1px solid black; */

}

.passwordContainer {
  width: 100%;
  height: 80%;

  background-color: var(--box-bg-tertiary);
  border-radius: 15px;
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    "iconDiv labelDiv"
    "iconDiv inputDiv";
}



.confirmPasswordWrapper {
  grid-area: confirmPasswordWrapper;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmPassContainer {
  width: 100%;
  height: 80%;
  background-color: var(--box-bg-tertiary);
  border-radius: 15px;
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    "iconDiv labelDiv"
    "iconDiv inputDiv";
  box-sizing: border-box;

}

.iconDiv {
  grid-area: iconDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.fieldIcon {
  color: var(--field-icon-color);

  font-size: 28px;
}

.labelDiv {
  grid-area: labelDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.labelDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  font-weight: 450;
  color: var(--secondary-font-color);
  margin-top: 5%;
}

.inputDiv {
  grid-area: inputDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 2%;
  box-sizing: border-box;
}

.inputDiv input {
  width: 90%;
  height: 60%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  background: none;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 450;
  color: var(--primary-font-color);
  letter-spacing: 0.3px;

}

.focusFieldStyle {
  border: 2px solid var(--ui-scheme-color);
}

input:focus {
  /* border-bottom: 1px solid #002A9A; */

}

.submitBtnWrapper {
  grid-area: submitBtnWrapper;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitBtn {
  width: 55%;
  height: 40%;
  border: none;
  background-color: var(--submit-btn-color);
  border-radius: 15px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 450;
  color: white;
  letter-spacing: 0.5px;
  /* outline: #AED2FF; */
}


/* ________________________________________________ */

.formFooterWrapper {
  grid-area: formFooterWrapper;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* border: 1px solid red; */
}

.formFooterWrapper p {
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  font-weight: 450;
  color: var(--primary-font-color);
}

.spanText {
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  font-weight: 550;
  color: var(--ui-scheme-color);
  margin-left: 2px;
  cursor: pointer;
}

.spanText:hover {
  text-decoration: underline;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

  .formPageWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    /* border: 1px solid orange; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
  }

  .formPageWrapper::-webkit-scrollbar {
    display: none;
  }

  .formWrapper {
    position: relative;
    width: 85%;
    height: 80%;
    min-height: 80%;
    /* aspect-ratio: 2/3; */
    /* aspect-ratio: 2/3; */
    border-radius: 20px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 12% 12% minmax(0, 1fr) 5%;
    grid-template-areas:
      "formHeaderWrapper formHeaderWrapper"
      "formMessageWrapper formMessageWrapper "
      "formTagContainer formTagContainer"
      "formFooterWrapper formFooterWrapper";
    justify-content: center;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* border: 1px solid orange; */
  }

  /* ________________________________________________ */
  .formHeaderWrapper {
    grid-area: formHeaderWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid orange; */
  }

  .formLabelDiv {
    position: relative;
    width: 80%;
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
  }

  .formLabelDiv p {
    font-size: 1.5rem;
    font-size: clamp(1.2rem, -0.40000000000000013rem + 8.0vw, 2.0rem) !important;

  }

  .formLabelDiv::after {
    position: absolute;
    content: '';
    width: 15%;
    height: 3px;
    border-radius: 20px;
    bottom: 0px;
  }

  /* ________________________________________________ */

  .formMessageWrapper {
    grid-area: formMessageWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid blue; */
    box-sizing: border-box;
  }

  .messageDiv {
    width: auto;
    max-width: 90%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 6px;
    padding: 1% 4% 1% 4%;
    /* margin-top: 3%; */

  }


  .messageDivError p {
    font-size: 0.7rem;
    font-size: clamp(0.55rem, -0.1499999999999999rem + 3.4999999999999996vw, 0.9rem) !important;

  }

  .messageDivSuccess p {
    font-size: 0.7rem;
    font-size: clamp(0.55rem, -0.1499999999999999rem + 3.4999999999999996vw, 0.9rem) !important;

  }

  .errorMessageIconDiv {
    height: 100%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    margin-right: 5px;
  }

  .warningIcon {
    font-size: 1rem;
    font-size: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;

    padding: 0px;
    margin: 0px;
  }

  /* ________________________________________________ */

  .formTagContainer {
    grid-area: formTagContainer;
    width: 85%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 20% 20% 20% 20% 20%;
    grid-template-areas:
      "firstNameWrapper LastNameWrapper"
      "emailAddressWrapper emailAddressWrapper "
      "passwordWrapper passwordWrapper"
      "confirmPasswordWrapper confirmPasswordWrapper"
      "submitBtnWrapper submitBtnWrapper";
    justify-content: center;
    align-items: center;
    /* border: 1px solid green; */
    margin: auto;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .firstNameWrapper {
    grid-area: firstNameWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
  }

  .firstNameContainer {
    width: 95%;
    height: 80%;
    border-radius: 15px;
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
      "iconDiv labelDiv"
      "iconDiv inputDiv";
    box-sizing: border-box;

  }

  .LastNameWrapper {
    grid-area: LastNameWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */

  }

  .lastNameContainer {
    width: 95%;
    height: 80%;
    border-radius: 15px;
    margin-left: 5%;
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
      "iconDiv labelDiv"
      "iconDiv inputDiv";
    box-sizing: border-box;

  }

  .emailAddressWrapper {
    grid-area: emailAddressWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
  }

  .emailAddressContainer {
    width: 100%;
    height: 80%;
    border-radius: 15px;
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
      "iconDiv labelDiv"
      "iconDiv inputDiv";
    box-sizing: border-box;

  }

  .passwordWrapper {
    grid-area: passwordWrapper;
    width: 100%;
    height: 90%;
    /* border: 1px solid black; */

  }

  .passwordContainer {
    width: 100%;
    height: 80%;
    border-radius: 15px;
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
      "iconDiv labelDiv"
      "iconDiv inputDiv";
  }



  .confirmPasswordWrapper {
    grid-area: confirmPasswordWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .confirmPassContainer {
    width: 100%;
    height: 80%;
    border-radius: 15px;
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
      "iconDiv labelDiv"
      "iconDiv inputDiv";
    box-sizing: border-box;

  }

  .iconDiv {
    grid-area: iconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fieldIcon {
    font-size: 1.9rem;
    font-size: clamp(2.1rem, 3.5000000000000004rem + -7.000000000000002vw, 1.4rem) !important;

  }

  .labelDiv {
    grid-area: labelDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .labelDiv p {
    font-size: 0.82rem;
    font-size: clamp(0.6rem, -1.1102230246251565e-16rem + 3.0000000000000004vw, 0.9rem) !important;
    margin-top: 5%;
  }

  .inputDiv {
    grid-area: inputDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 2%;
    box-sizing: border-box;
  }

  .inputDiv input {
    width: 90%;
    height: 60%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    background: none;
    outline: none;
    font-size: 0.8rem;
    font-size: clamp(0.6rem, -1.1102230246251565e-16rem + 3.0000000000000004vw, 0.9rem) !important;

    letter-spacing: 0.3px;

  }


  input:focus {
    /* border-bottom: 1px solid #002A9A; */

  }

  .submitBtnWrapper {
    grid-area: submitBtnWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submitBtn {
    width: 55%;
    height: 40%;
    border: none;
    border-radius: 15px;
    font-size: 0.8rem;
    font-size: clamp(0.7rem, 0.09999999999999987rem + 3.0000000000000004vw, 1.0rem) !important;

    letter-spacing: 0.5px;
    /* outline: #AED2FF; */
  }


  /* ________________________________________________ */

  .formFooterWrapper {
    grid-area: formFooterWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* border: 1px solid red; */
  }

  .formFooterWrapper p {
    font-size: 0.8rem;
    font-size: clamp(0.6rem, -1.1102230246251565e-16rem + 3.0000000000000004vw, 0.9rem) !important;

    white-space: nowrap;

  }

  .spanText {
    font-size: 0.8rem;
    font-size: clamp(0.6rem, -1.1102230246251565e-16rem + 3.0000000000000004vw, 0.9rem) !important;

    margin-left: 1%;
  }

}