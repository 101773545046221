@import "../../variable.modules.css";

.cardWrapper {
  /* display: flex; */
  display: none;
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--box-bg-primary);
  padding-left: 5%;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

.cardWrapperClose {
  /* display: flex; */
  display: none;
  width: 0%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--box-bg-primary);
  padding-left: 0%;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  scroll-behavior: smooth;


}

.cardContainer {
  width: 70%;
  height: 90%;
  /* border: 1px solid red; */
  /* border: 1px solid black; */
  display: grid;
  grid-template-columns: 10% 90%;
  grid-template-rows: 15% 20% 15% 10% 20% 20%;
  grid-template-rows: 10% 20% 15% 15% 20% 20%;

  grid-template-areas:
    "cardHeader cardHeader"
    "logoTitleWrapper logoTitleWrapper"
    "categoryWrapper categoryWrapper"
    "appWebSiteWrapper appWebSiteWrapper"
    "usernameWrapper usernameWrapper"
    "passwordWrapper passwordWrapper";
  box-sizing: border-box;
}

.cardHeader {
  grid-area: cardHeader;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid orange; */
}

.backBtnContainer {
  width: 50%;
  height: 100%;
  /* border: 1px solid blue; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 0%;
  padding-top: 0%;

}

.backBtnDiv {
  width: auto;
  height: 22px;
  height: 25px;
  /* height: 20px; */
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0%;
  padding-right: 5%;
  border-radius: 5px;
  margin-right: 10px;
  /* transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); */
  cursor: pointer;

}

.curdBtnContainer {
  width: 50%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.editBtnDiv {
  width: auto;
  height: 22px;
  height: 25px;

  /* border: 1px solid black; */
  background-color: #9DC1FA;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  border-radius: 5px;
  margin-right: 10px;
  /* transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  cursor: pointer;

}

.editBtnDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 0.65rem;
  font-weight: var(--bolder-font);
  color: #002A9A;
}

.saveBtnDiv {
  width: auto;
  height: 22px;
  height: 25px;

  /* border: 1px solid black; */
  background-color: #58BF6F;
  /* background-color: white; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  border-radius: 5px;
  margin-right: 10px;
  /* transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  cursor: pointer;

}

.saveBtnDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 0.65rem;
  font-weight: var(--bold-font);
  color: white;
}

.cancelBtnDiv {
  width: auto;
  height: 22px;
  height: 25px;
  /* border: 1px solid black; */
  background-color: #E2E1E4;
  background-color: #B4B3BA;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  border-radius: 5px;
  margin-right: 10px;
  /* transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px; */
  cursor: pointer;
}

.cancelBtnDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 0.65rem;
  font-weight: 450 !important;
  /* color: #5B5A68; */
  color: white;
}

.deleteBtnDiv {
  position: relative;
  width: auto;
  height: 25px;
  /* border: 1px solid black; */
  background-color: #D64339;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  border-radius: 5px;
  margin-right: 10px;
  /* transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  cursor: pointer;
}

.spinner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid orange; */
}

.deleteBtnDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 0.65rem;
  font-weight: var(--bold-font);
  color: white;
}

/* .favBtnDiv {
  width: 150px;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
} */

.crudIcons {
  margin-right: 5px;
}

.favBtnDiv {
  height: 60%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
  cursor: pointer;

}


.logoTitleWrapper {
  grid-area: logoTitleWrapper;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid blue; */
  padding-left: 0%;
}

.logoTitleContainer {
  width: 100%;
  height: 85%;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0%;
}

.logoContainer {
  /* width: auto; */
  /* width: 30%; */
  height: 100%;
  aspect-ratio: 1/1;
  /* border: 1px solid orange; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid green; */
  margin-right: 2%;
}


.logoDiv {
  /* width: 80%; */
  aspect-ratio: 1/1;
  height: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: var(--box-bg-tertiary); */
  background-color: var(--logo-div-bg);
}

.logoDivActive {
  border: 3px solid #6f9bd0b5;
}

.logoIcon {
  width: 100%;
  height: 100%;
}

.titleContainer {
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 5%;
  /* border: 1px solid red; */
  border-radius: 0.8rem;
}

.titleLabelDiv {
  width: 80%;
  height: 80%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.titleLabelDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 0.85rem;
  font-weight: var(--bolder-font);
  color: var(--secondary-font-color);
}

.titleInputDiv {
  width: 80%;
  height: 80%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

}



.titleInputActive {
  width: 90%;
  height: 100%;
  border-bottom: none;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: var(--bold-font);
  color: var(--primary-font-color);
}

.titleInputNotActive {
  width: 90%;
  height: 100%;
  border-bottom: none;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: var(--bold-font);
  color: var(--primary-font-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.categoryWrapper {
  grid-area: categoryWrapper;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid rgb(182, 69, 88); */
  padding-left: 0%;

}

.categoryContainer {
  width: 100%;
  height: 60%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--box-bg-tertiary);
  border-radius: 10px;

}

.categoryLabelDiv {
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5%;
}

.categoryLabelDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: var(--bolder-font);
  color: var(--primary-font-color);
}

.catergoryInputDiv {
  position: relative;
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* border: 1px solid black; */
}

.categoryInput {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  font-weight: var(--bold-font);
  color: var(--ui-scheme-color);
  background-color: var(--category-box-bg);
  border: none;
  outline: none !important;
  height: 75%;
  width: 80%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.popUpBtnIconDiv {
  width: 15%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}

.popUpIcon {
  font-size: 20px;
  color: var(--primary-font-color);
  cursor: pointer;

}

.categoryInput:focus {
  /* border: 1px solid #0475ff84; */
}

.inputPopUpDiv {
  top: 115%;
  position: absolute;
  width: 150px;
  right: 10%;
  height: auto;
  background-color: var(--box-bg-tertiary-drk);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 10%;
  border-radius: 5px;
  box-shadow: var(--applogo-box-shadow);
}

.inputPopUpText {
  font-family: "Poppins", sans-serif;
  font-size: 0.75rem;
  font-weight: var(--bold-font);
  color: var(--primary-font-color);
  margin: 5px 0px 0px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid var(--box-bg-tertiary);
  padding-left: 8%;
  padding-right: 5%;
  width: 90%;
  letter-spacing: 0.3px;
}

.inputPopUpText:hover {
  width: 80%;
  background-color: #a8ccf9b5;
  padding-left: 5%;
  padding-right: 5%;
  border-radius: 5px;
}

.appWebSiteWrapper {
  grid-area: appWebSiteWrapper;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid purple; */
  padding-left: 0%;
}


.appWebSiteContainer {
  width: 100%;
  height: 60%;
  /* border: 1px solid black; */
  display: flex;
  display: grid;
  grid-template-columns: 20% 40% 40%;
  grid-template-rows: 100%;
  grid-template-areas:
    "appWebSiteIconDiv appWebsiteLabelDiv appWebSiteInputDiv";
  background-color: var(--box-bg-tertiary);
  /* background-color: var(--box-bg-tertiary); */
  border-radius: 10px;

}

.appWebSiteIconDiv {
  grid-area: appWebSiteIconDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.websiteIcon {
  font-size: 1.5rem;
  color: var(--field-icon-color);

}

.appWebsiteLabelDiv {
  grid-area: appWebsiteLabelDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.appWebsiteLabelDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 0.85rem;
  font-weight: var(--bolder-font);
  color: var(--secondary-font-color);
}

.appWebSiteInputDiv {
  grid-area: appWebSiteInputDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
}


.appWebSiteInputActive {
  width: 80%;
  height: auto;
  border-bottom: none;
  border-top: none;
  border-left: none;
  border-right: none;
  /* border: 1px solid #7E8DA4; */
  outline: none;
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: var(--bold-font);
  color: var(--primary-font-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.appWebSiteInputNotActive {
  width: 80%;
  height: auto;
  border: none;
  /* border: 1px solid #7E8DA4; */
  outline: none;
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: var(--bold-font);
  color: var(--primary-font-color);

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.usernameWrapper {
  grid-area: usernameWrapper;
  width: 100%;
  height: 100%;
  /* border: 1px solid orange; */
  padding-left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

}

.usernameContainer {
  width: 100%;
  height: 70%;
  /* border: 1px solid black; */
  display: grid;
  align-items: center;
  grid-template-columns: 20% 80%;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    "usernameIconDiv usernameLabelDiv"
    "usernameIconDiv usernameInputDiv";
  background-color: var(--box-bg-tertiary);
  border-radius: 10px;


}

.usernameIconDiv {
  grid-area: usernameIconDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.usernameIcon {
  width: 50%;
  height: 50%;
  color: var(--field-icon-color);
}

.usernameLabelDiv {
  grid-area: usernameLabelDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.usernameLabelDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: var(--bolder-font);
  color: var(--secondary-font-color);
}

.usernameInputDiv {
  grid-area: usernameInputDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.userNameInputActive {
  width: 80%;
  height: auto;
  border-bottom: none;
  border-top: none;
  border-left: none;
  border-right: none;
  /* border: 1px solid #7E8DA4; */
  outline: none;
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: var(--bold-font);
  color: var(--primary-font-color);

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.userNameInputNotActive {
  width: 80%;
  height: auto;
  border: none;
  /* border: 1px solid #7E8DA4; */
  outline: none;
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: var(--bold-font);
  color: var(--primary-font-color);

  display: flex;
  justify-content: flex-start;
  align-items: center;
}



.passwordWrapper {
  grid-area: passwordWrapper;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid orange; */
  padding-left: 0%;

}

.passwordContainer {
  width: 100%;
  height: 70%;
  /* border: 1px solid black; */
  display: grid;
  align-items: center;
  grid-template-columns: 20% 80%;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    "passwordIconDiv passwordLabelDiv"
    "passwordIconDiv passwordInputDiv";
  background-color: var(--box-bg-tertiary);
  border-radius: 10px;
}

.passwordIconDiv {
  grid-area: passwordIconDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.passwordIcon {
  width: 55%;
  height: 55%;
  color: var(--field-icon-color);
}

.passwordLabelDiv {
  grid-area: passwordLabelDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.passwordLabelDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: var(--bolder-font);
  color: var(--secondary-font-color);
}

.passwordInputDiv {
  grid-area: passwordInputDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.passwordInput {
  height: 100%;
  width: 80%;
  border: none;
  outline: none;
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: var(--bold-font);
  color: var(--primary-font-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* border: 1px solid black; */
}

.passInputEditMode {
  width: 70%;
  /* border: 1px solid red; */
}

.passwordVisibilityIconDiv {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* border: 1px solid red; */
}

.passwordVisibilityIconDivEditMode {
  width: 15%;
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* border: 1px solid red; */
}

.passVisibilityIcon {
  aspect-ratio: 1/1;
  height: 60%;
  cursor: pointer;
}

.passwordGeneratorIconContainer {
  position: relative;
  width: 0%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* border: 1px solid black; */
  overflow: hidden;
  opacity: 0;
}

.passwordGeneratorIconContainerEditMode {
  width: 15%;
  height: 100%;
  /* border: 1px solid red; */
  opacity: 1;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.passwordGeneratorIconDiv {
  height: 45%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.passGeneratorIcon {
  width: 100%;
  height: 100%;
  cursor: pointer;

}

::-ms-reveal {
  display: none;
}


.focusFieldStyle {
  border: 2px solid var(--ui-scheme-color);
}


@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

  .cardWrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    padding-left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 0%;
    margin-bottom: 0.2%;
    transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .cardWrapperClose {
    display: flex;
    width: 100%;
    height: 0%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0%;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
    transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .blurBgWrapper {
    opacity: 0.5;
  }

  .cardContainer {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 25% minmax(0, 1fr);
    grid-template-rows: 10% 17% 14% 14% 22% 22%;
    grid-template-rows: 10% 18% 14% 14% 22% 22%;
    grid-template-rows: 10% 20% 15% 15% 20% 20%;
    grid-template-areas:
      "cardHeader cardHeader"
      "logoTitleWrapper logoTitleWrapper"
      "categoryWrapper categoryWrapper"
      "appWebSiteWrapper appWebSiteWrapper"
      "usernameWrapper usernameWrapper"
      "passwordWrapper passwordWrapper";
    padding-bottom: 0%;
    padding-top: 0%;
    box-sizing: border-box;
    opacity: 1;

  }

  .backBtnContainer {
    width: 30%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5%;
    padding-top: 0%;
  }

  .backBtnDiv {
    width: 80%;
    height: 60%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }


  .curdBtnContainer {
    width: 70%;
    height: 100%;
    padding-right: 5%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .editBtnDiv {
    width: auto;
    height: 55%;

  }

  .editBtnDiv p {
    font-size: clamp(0.65rem, 0.2500000000000001rem + 1.9999999999999998vw, 0.85rem) !important;
    /* font-size: clamp(0.65rem, 0.15000000000000002rem + 2.5vw, 0.9rem) !important; */

  }

  .saveBtnDiv {
    width: auto;
    height: 55%;
    padding-left: 4%;
    padding-right: 4%;
    border-radius: 5px;
    margin-right: 10px;
    /* border: 1px solid red; */
  }

  .saveBtnDiv p {
    font-size: clamp(0.65rem, 0.2500000000000001rem + 1.9999999999999998vw, 0.85rem) !important;
    /* font-size: clamp(0.65rem, 0.15000000000000002rem + 2.5vw, 0.9rem) !important; */

  }

  .cancelBtnDiv {
    width: auto;
    height: 55%;
    padding-left: 4%;
    padding-right: 4%;
    margin-right: 10px;

  }

  .cancelBtnDiv p {
    font-size: clamp(0.65rem, 0.2500000000000001rem + 1.9999999999999998vw, 0.85rem) !important;
    /* font-size: clamp(0.65rem, 0.15000000000000002rem + 2.5vw, 0.9rem) !important; */
  }

  .deleteBtnDiv {
    width: auto;
    height: 55%;
    padding-left: 4%;
    padding-right: 4%;
    margin-right: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0.25rem;
  }

  .deleteBtnDiv p {
    font-size: clamp(0.65rem, 0.2500000000000001rem + 1.9999999999999998vw, 0.85rem) !important;
    /* font-size: clamp(0.65rem, 0.15000000000000002rem + 2.5vw, 0.9rem) !important; */
  }

  .crudIcons {
    margin-right: 0.3rem;
    font-size: 0.8rem;
    font-size: 1.2rem;
    /* font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important; */
    font-size: clamp(0.8rem, 2.220446049250313e-16rem + 3.9999999999999996vw, 1.2rem) !important;
  }


  .logoTitleWrapper {
    grid-area: logoTitleWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0%;
  }

  .logoTitleContainer {
    width: 90%;
    height: 100%;
    /* border: 1px solid white; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .logoContainer {
    /* width: auto; */
    /* width: 30%; */
    height: 100%;
    aspect-ratio: 1/1;
    /* border: 1px solid orange; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* border: 1px solid green; */
    margin-right: 2%;
  }

  .logoDiv {
    /* width: 80%; */
    aspect-ratio: 1/1;
    height: 90%;
    /* border-radius: 20px; */
    /* border-radius: 0.9rem; */
    /* border-radius: 1.3rem; */
    border-radius: clamp(0.9rem, 0.09999999999999998rem + 4.0vw, 1.3rem) !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logoIcon {
    width: 100%;
    height: 100%;
  }

  .titleContainer {
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 5%;
  }

  .titleLabelDiv {
    width: 80%;
    height: 80%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .titleLabelDiv p {
    font-size: 0.7rem;
    font-size: 1rem;
    font-size: clamp(0.7rem, 0.09999999999999987rem + 3.0000000000000004vw, 1.0rem) !important;
  }

  .titleInputDiv {
    width: 100%;
    height: 80%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .titleInputActive {
    font-size: 1.5rem;
    font-size: 1rem;
    font-size: clamp(1.1rem, 0.30000000000000027rem + 3.9999999999999996vw, 1.5rem) !important;

  }

  .titleInputNotActive {
    font-size: 1.1rem;
    font-size: 1.5rem;
    font-size: clamp(1.1rem, 0.30000000000000027rem + 3.9999999999999996vw, 1.5rem) !important;
  }

  .categoryWrapper {
    grid-area: categoryWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid rgb(182, 69, 88); */
    padding-left: 0%;

  }

  .categoryContainer {
    width: 90%;
    height: 60%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    /* border-radius: 0.75rem; */
    border-radius: clamp(0.5rem, 0.0rem + 2.5vw, 0.75rem) !important;
  }

  .categoryLabelDiv {
    width: 40%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5%;
  }

  .categoryLabelDiv p {
    font-size: 1rem;
    font-size: 1.5rem;
    /* font-size: clamp(1.0rem, 0.3999999999999999rem + 3.0000000000000004vw, 1.3rem) !important; */
    margin: 0px;
    padding: 0px;
    font-size: clamp(1.0rem, 0.0rem + 5.0vw, 1.5rem) !important;
  }

  .catergoryInputDiv {
    position: relative;
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* border: 1px solid black; */
  }

  .categoryInput {
    outline: none !important;
    height: 80%;
    width: 85%;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 0.85rem;
    font-size: 1.35rem;
    /* font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important; */
    font-size: clamp(0.85rem, -0.15000000000000024rem + 5.000000000000001vw, 1.35rem) !important;
  }

  .popUpBtnIconDiv {
    width: 15%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popUpIcon {
    font-size: 22px;
  }

  .categoryInput:focus {
    /* border: 1px solid #0475ff84; */
  }

  .inputPopUpDiv {
    top: 110%;
    right: 20%;
    position: absolute;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 10%;
    padding-right: 30%;
    border-radius: 5px;
  }

  .inputPopUpText {
    margin: 15% 0px 0px 0px;
    font-size: clamp(0.75rem, -0.1499999999999999rem + 4.5vw, 1.2rem) !important;
  }

  .inputPopUpText:hover {
    /* background-color: #0073ff; */
    width: 80%;
  }

  .appWebSiteWrapper {
    grid-area: appWebSiteWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid purple; */
    padding-left: 0%;
  }


  .appWebSiteContainer {
    width: 90%;
    height: 60%;
    /* border: 1px solid black; */
    display: flex;

    display: grid;
    grid-template-columns: 20% 40% 40%;
    grid-template-rows: 100%;
    grid-template-areas:
      "appWebSiteIconDiv appWebsiteLabelDiv appWebSiteInputDiv";
    background-color: var(--box-bg-tertiary);
    /* border-radius: 0.5rem; */
    /* border-radius: 0.75rem; */
    border-radius: clamp(0.5rem, 0.0rem + 2.5vw, 0.75rem) !important;
  }

  .appWebSiteIconDiv {
    grid-area: appWebSiteIconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .websiteIcon {
    height: 50%;
  }

  .appWebsiteLabelDiv {
    grid-area: appWebsiteLabelDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .appWebsiteLabelDiv p {
    font-size: 0.9rem;
    font-size: 1.2rem;
    /* font-size: clamp(0.85rem, 0.34999999999999976rem + 2.5000000000000013vw, 1.1rem) !important; */
    /* font-size: clamp(0.8rem, 0.40000000000000013rem + 1.9999999999999998vw, 1.0rem) !important; */
    font-size: clamp(0.9rem, 0.30000000000000016rem + 2.999999999999999vw, 1.2rem) !important;
    font-weight: var(--bolder-font);
  }

  .appWebSiteInputDiv {
    grid-area: appWebSiteInputDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
  }

  .appWebSiteInputActive {
    font-size: 0.8rem;
    font-size: clamp(0.75rem, 0.1499999999999999rem + 3.0000000000000004vw, 1.05rem) !important;
  }

  .appWebSiteInputNotActive {
    font-size: 0.75rem;
    font-size: 1.05rem;
    /* font-size: 1rem; */
    /* font-size: clamp(0.75rem, 0.3500000000000001rem + 1.9999999999999998vw, 0.95rem) !important; */
    /* font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important; */
    font-size: clamp(0.75rem, 0.1499999999999999rem + 3.0000000000000004vw, 1.05rem) !important;
    font-weight: var(--bolder-font);
  }

  .usernameWrapper {
    grid-area: usernameWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid orange; */
    padding-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .usernameContainer {
    width: 90%;
    height: 70%;
    /* border: 1px solid black; */
    display: grid;
    align-items: center;
    grid-template-columns: 20% 80%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
      "usernameIconDiv usernameLabelDiv"
      "usernameIconDiv usernameInputDiv";

    /* border-radius: 0.7rem; */
    /* border-radius: 1.1rem; */

    border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;
  }


  .usernameIconDiv {
    grid-area: usernameIconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .usernameIcon {
    width: 50%;
    height: 50%;
  }

  .usernameLabelDiv {
    grid-area: usernameLabelDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .usernameLabelDiv p {
    font-weight: var(--bolder-font);
    font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;
  }

  .usernameInputDiv {
    grid-area: usernameInputDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .userNameInputActive {
    font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;
  }

  .userNameInputNotActive {
    font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;

  }


  /* .usernameInputDiv input {
        width: 80%;
        height: 100%;
        border-bottom: none;
        border-top: none;
        border-left: none;
        border-right: none;
        outline: none;
        background: transparent;
        font-family: "Poppins", sans-serif;
        font-size: 0.8rem;
        font-weight:var(--bold-font);
        color: black;
    } */


  .passwordWrapper {
    grid-area: passwordWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid orange; */
    padding-left: 0%;
  }

  .passwordContainer {
    width: 90%;
    height: 70%;
    /* border: 1px solid black; */
    display: grid;
    align-items: center;
    grid-template-columns: 20% 80%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
      "passwordIconDiv passwordLabelDiv"
      "passwordIconDiv passwordInputDiv";
    background-color: var(--box-bg-tertiary);
    border-radius: 1.1rem;
    border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;
  }

  .passwordIconDiv {
    grid-area: passwordIconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .passwordIcon {
    width: 55%;
    height: 55%;
  }

  .passwordLabelDiv {
    grid-area: passwordLabelDiv;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .passwordLabelDiv p {
    font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;
  }

  .passwordInput {
    height: 100%;
    width: 80%;
    border: none;
    outline: none;
    background: transparent;
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;
    font-weight: var(--bold-font);
    color: var(--primary-font-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .passInputEditMode {
    width: 70%;
    /* border: 1px solid red; */
  }

  .passwordVisibilityIconDiv {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    /* border: 1px solid red; */
  }

  .passwordVisibilityIconDivEditMode {
    width: 15%;
    display: flex;
    /* justify-content: flex-start; */
    align-items: center;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    /* border: 1px solid red; */
  }

  .passVisibilityIcon {
    aspect-ratio: 1/1;
    height: 60%;
  }

  .passwordGeneratorIconContainer {
    position: relative;
    width: 0%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    /* border: 1px solid black; */
    overflow: hidden;
    opacity: 0;
  }

  .passwordGeneratorIconContainerEditMode {
    width: 15%;
    height: 100%;
    /* border: 1px solid red; */
    opacity: 1;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .passwordGeneratorIconDiv {
    height: 45%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .passGeneratorIcon {
    width: 100%;
    height: 100%;
  }



  /* ----------------------------------------------
  * Generated by Animista on 2022-11-28 19:46:20
  * Licensed under FreeBSD License.
  * See http://animista.net/license for more info. 
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */

  /**
  * ----------------------------------------
  * animation slide-in-bottom
  * ----------------------------------------
  */
  @-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
      transform: translateY(1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
      transform: translateY(1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {

  .cardWrapper {
    /* display: flex; */
    display: none;
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--box-bg-primary);
    /* background-color: transparent; */
    padding-left: 5%;
    webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* border: 1px solid black; */
  }

  .cardWrapperClose {
    /* display: flex; */
    display: none;
    width: 0%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--box-bg-primary);
    padding-left: 0%;
    webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    scroll-behavior: smooth;

  }

  .cardContainer {
    width: 95%;
    height: 50%;
    /* border: 1px solid red; */
    /* border: 1px solid black; */
    display: grid;
    grid-template-columns: 10% 90%;
    grid-template-rows: 15% 20% 15% 10% 20% 20%;
    grid-template-rows: 10% 20% 15% 15% 20% 20%;
    grid-template-areas:
      "cardHeader cardHeader"
      "logoTitleWrapper logoTitleWrapper"
      "categoryWrapper categoryWrapper"
      "appWebSiteWrapper appWebSiteWrapper"
      "usernameWrapper usernameWrapper"
      "passwordWrapper passwordWrapper";
    box-sizing: border-box;
    margin-top: 5%;
    /* background-color: white; */
  }

}