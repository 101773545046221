@import "../../variable.modules.css";

.loginInWrapper {
  position: relative;
  display: grid;
  grid-template-columns: 80px minmax(0, 1fr) minmax(0, 0.2fr);
  grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-areas:
    "logoWrapper titleWrapper favBtnWrapper"
    "logoWrapper usernameWrapper favBtnWrapper";
  align-items: center;
  width: 280px;
  height: 75px;
  aspect-ratio: 3.73/1 !important;
  border-radius: 0.9rem;
  margin: 15px 15px 15px 15px;
  background-color: var(--box-bg-primary);
  /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.075), 0 5px 5px rgba(0, 0, 0, 0.048); */
  box-shadow: var(--list-wrapper-box-shadow);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  cursor: pointer;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: logoWrapper;
  /* border: 1px solid black; */
  width: 100%;
  height: 100%;
}

.logoDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid rgba(128, 128, 128, 0.543); */
  width: 80%;
  height: 80%;
  border-radius: 10px;
  background-color: #F3F5F9;
  background-color: var(--logo-div-bg);

}

.titleWrapper {
  grid-area: titleWrapper;
  /* border: 1px solid black; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding-left: 5%;
  overflow: hidden;

}

.titleText {
  font-family: "Poppins", sans-serif;
  font-weight: var(--bolder-font);
  color: var(--primary-font-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  font-weight: var(--bolder-font);
}

.usernameWrapper {
  grid-area: usernameWrapper;
  /* border: 1px solid black; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5%;

}

.userNameText {
  font-family: "Poppins", sans-serif;
  font-weight: var(--bolder-font);
  color: var(--secondary-font-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.88rem;
  font-weight: 450;
}

.favBtnWrapper {
  grid-area: favBtnWrapper;
  /* border: 1px solid black; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.favBtnDiv {
  width: 60%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {


  .loginInWrapper {
    /* width: 83% !important;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    box-shadow: var(--list-wrapper-box-shadow); */
    position: relative;
    display: grid;
    grid-template-columns: 80px minmax(0, 1fr) minmax(0, 0.2fr);
    grid-template-columns: 25% minmax(0, 1fr) minmax(0, 0.2fr);
    grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-areas:
      "logoWrapper titleWrapper favBtnWrapper"
      "logoWrapper usernameWrapper favBtnWrapper";
    width: 83% !important;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    /* border: 1px solid red; */
    padding: 0%;
    margin: 0.80rem auto 0.80rem auto;
    border-radius: 18px;
  }

  .logoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: logoWrapper;
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid rgba(128, 128, 128, 0.543); */
    width: 85%;
    height: 85%;
    border-radius: 10px;
    border-radius: 13px;

  }
}


/* 
/user/favorites/logins
*/