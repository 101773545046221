.forgotPassPage {
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgotPassWrapper {
  position: relative;
  width: 25%;
  aspect-ratio: 3/2.8;
  border-radius: 30px;
  background-color: var(--box-bg-primary);
  display: grid;
  grid-template-columns: 100%;
  /* grid-template-rows: 30% auto 25% 20%; */
  grid-template-rows: 30% auto 30% 20% 10%;
  grid-template-rows: 30% auto 30% 18% 10%;
  grid-template-areas:
    "formHeaderWrapper formHeaderWrapper"
    "formMessageWrapper formMessageWrapper"
    "emailWrapper emailWrapper"
    "sendResetLinkBtnWrapper sendResetLinkBtnWrapper"
    "formFooterWrapper formFooterWrapper";
  box-shadow: var(--dashboard-countDiv-box-shadow);
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* border: 1px solid springgreen; */
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.formHeaderWrapper {
  grid-area: formHeaderWrapper;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.formLabelDiv {
  position: relative;
  width: auto;
  max-width: 95%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
}

.formLabelDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 0.95rem;
  font-weight: var(--bolder-font);
  color: var(--primary-font-color);
}

.formLabelDiv::after {
  position: absolute;
  content: '';
  background-color: var(--ui-scheme-color);
  width: 15%;
  height: 3px;
  border-radius: 20px;
  bottom: 0px;
}

.formMessageWrapper {
  grid-area: formMessageWrapper;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid blue; */
  box-sizing: border-box;
  padding-bottom: 2%;
}

.messageDiv {
  width: auto;
  max-width: 85%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 6px;
  padding: 1% 4% 1% 4%;
  /* margin-top: 3%; */

}

.messageDivError {
  background-color: #D64339;
}

.messageDivError p {
  font-family: "Poppins", sans-serif;
  font-size: 0.7rem;
  font-weight: var(--bolder-font);
  color: white;
}

.messageDivSuccess {
  background-color: #58BF6F;

}

.messageDivSuccess p {
  font-family: "Poppins", sans-serif;
  font-size: 0.7rem;
  font-weight: var(--bold-font);
  color: white;
}

/* .messageDiv p {} */
.errorMessageIconDiv {
  width: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
  margin-right: 5px;
}

.warningIcon {
  font-size: 15px !;
  color: white;
  padding: 0px;
  margin: 0px;
}

.emailWrapper {
  grid-area: emailWrapper;
  width: 90%;
  height: 100%;
  /* border: 1px solid black; */
  margin: auto;
}

.emailAddressContainer {
  width: 100%;
  height: 80%;
  background-color: var(--box-bg-tertiary);
  border-radius: 15px;
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    "iconDiv labelDiv"
    "iconDiv inputDiv";
  box-sizing: border-box;

}

.iconDiv {
  grid-area: iconDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.fieldIcon {
  color: var(--field-icon-color);
  font-size: 1.9rem;
}

.labelDiv {
  grid-area: labelDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.labelDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 0.85rem;
  font-weight: var(--bolder-font);
  color: var(--secondary-font-color);
  margin-top: 5%;
}

.inputDiv {
  grid-area: inputDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 2%;
  box-sizing: border-box;
}

.inputDiv input {
  width: 90%;
  height: 60%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  background: none;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: var(--bolder-font);
  color: var(--primary-font-color);
  letter-spacing: 0.3px;

}

.focusFieldStyle {
  border: 2px solid var(--ui-scheme-color);
}

.sendResetLinkBtnWrapper {
  grid-area: sendResetLinkBtnWrapper;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.sendLinkBtn {
  width: auto;
  height: 55%;
  border: none;
  background-color: var(--submit-btn-color);
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: 450;
  color: white;
  letter-spacing: 0.5px;
  /* outline: #AED2FF; */
  padding-left: 5%;
  padding-right: 5%;
  cursor: pointer;
}

.formFooterWrapper {
  grid-area: formFooterWrapper;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.formFooterWrapper p {
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: var(--bolder-font);
  color: var(--ui-scheme-color);
  text-decoration: none;
}

Link {
  text-decoration: none;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .forgotPassWrapper {
    position: relative;
    width: 83%;
    aspect-ratio: 3/2.8;
    border-radius: 30px;

  }



  .formLabelDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 0.95rem;
    font-weight: var(--bolder-font);
    color: var(--primary-font-color);
  }

  .messageDivError p {
    font-size: clamp(0.55rem, -0.1499999999999999rem + 3.4999999999999996vw, 0.9rem) !important;

  }

  .warningIcon {
    font-size: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;

  }

  .messageDivSuccess p {
    font-family: "Poppins", sans-serif;
    font-size: 0.7rem;
    font-weight: var(--bold-font);
    color: white;
  }


  .fieldIcon {
    font-size: clamp(2.1rem, 3.5000000000000004rem + -7.000000000000002vw, 1.4rem) !important;
  }

  .labelDiv p {
    font-size: clamp(0.6rem, -1.1102230246251565e-16rem + 3.0000000000000004vw, 0.9rem) !important;
  }

  .inputDiv input {
    font-size: clamp(0.6rem, -1.1102230246251565e-16rem + 3.0000000000000004vw, 0.9rem) !important;
  }


  .sendLinkBtn {
    font-size: clamp(0.7rem, 0.09999999999999987rem + 3.0000000000000004vw, 1.0rem) !important;
  }

  .formFooterWrapper p {
    font-size: clamp(0.6rem, -1.1102230246251565e-16rem + 3.0000000000000004vw, 0.9rem) !important;
  }

}