.signInPage {
  position: relative;
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.spinner {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.appBioSection {
  position: relative;
  width: 60%;
  height: 100%;
  /* border: 1px solid orangered; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* ______________ */
.svgWrapper {
  width: 100%;
  height: 100%;
  /* border: 1px solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ------------------------------------- */
.svgContainer {
  width: 80%;
  height: 80%;
  /* border: 1px solid green; */
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  grid-template-rows: 20% 20% 20% 20% 20%;
  grid-template-areas:
    " pdfSvgWrapper  docSvgWrapper cardSvgWrapper"
    " pdfSvgWrapper  docSvgWrapper cardSvgWrapper"
    " pdfSvgWrapper  phoneSvgWrapper cardSvgWrapper"
    " passSvgWrapper  phoneSvgWrapper cardSvgWrapper"
    " passSvgWrapper  phoneSvgWrapper cardSvgWrapper";
}


.docSvgWrapper {
  grid-area: docSvgWrapper;
  width: 100%;
  height: 100%;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdfSvgWrapper {
  grid-area: pdfSvgWrapper;
  width: 100%;
  height: 100%;
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardSvgWrapper {
  grid-area: cardSvgWrapper;
  width: 100%;
  height: 100%;
  /* background-color: green; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.passSvgWrapper {
  grid-area: passSvgWrapper;
  width: 100%;
  height: 100%;
  /* background-color: rosybrown; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.phoneSvgWrapper {
  grid-area: phoneSvgWrapper;
  width: 100%;
  height: 100%;
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdfSvg {
  width: 80%;
  height: 80%;
}

.docsSvg {
  width: 100%;
  height: 100%;
}

.passSvg {
  width: 80%;
  height: 80%;
}

.cardSvg {
  width: 80%;
  height: 80%;
}

.phoneSvg {
  width: 80%;
  height: 80%;
}

/* ------------------------------------- */



.bioTextWrapper {
  width: 100%;
  height: 100%;
  /* border: 1px solid orange; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.textDiv1 {
  width: 70%;
  height: 25%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.textDiv1 p {
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: var(--boldest-font);
  color: var(--primary-font-color);
}

.textDiv2 {
  width: 50%;
  height: 25%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.textDiv2 p {
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: var(--bolder-font);
  color: #6A82A7;

}

.textDiv3 {
  width: 50%;
  height: 25%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.textDiv3 p {
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  color: #6A82A7;
  font-weight: var(--bolder-font);
}

.textDiv4 {
  width: 70%;
  height: 25%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
}

.bioSpanText {
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: var(--bolder-font);
  color: var(--ui-scheme-color);
}

.textDiv4 p {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  font-weight: var(--bolder-font);
  color: #6A82A7;

}


/* -------------------------------------------------- */

.signInformSection {
  position: relative;
  width: 40%;
  height: 100%;
  /* border: 1px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-left: 1px solid gray; */
}

.formPageWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  /* border: 1px solid orange; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.formPageWrapper::-webkit-scrollbar {
  display: none;
}



.formWrapper {

  position: relative;
  width: 300px;
  aspect-ratio: 2/2.5;
  border-radius: 30px;
  background-color: var(--box-bg-primary);
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 20% 10% minmax(0, 1fr) 8%;
  grid-template-rows: 20% auto minmax(0, 1fr) 8%;
  grid-template-areas:
    "formHeaderWrapper formHeaderWrapper"
    "formMessageWrapper formMessageWrapper "
    "formTagContainer formTagContainer"
    "formFooterWrapper formFooterWrapper";

  justify-content: center;
  align-items: center;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: var(--dashboard-countDiv-box-shadow);
}

/* ________________________________________________ */
.formHeaderWrapper {
  grid-area: formHeaderWrapper;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid orange; */
}

.formLabelDiv {
  position: relative;
  width: 80%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
}

.formLabelDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: var(--boldest-font);
  color: var(--primary-font-color);
}

.formLabelDiv::after {
  position: absolute;
  content: '';
  background-color: var(--ui-scheme-color);
  width: 15%;
  height: 3px;
  border-radius: 20px;
  bottom: 0px;
}

/* ________________________________________________ */

.formMessageWrapper {
  grid-area: formMessageWrapper;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* border: 1px solid blue; */
  box-sizing: border-box;
  padding-bottom: 2%;
}

.messageDiv {
  width: auto;
  max-width: 85%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 6px;
  padding: 1% 4% 1% 4%;
  /* margin-top: 3%; */

}

.messageDivError {
  background-color: #D64339;
}

.messageDivError p {
  font-family: "Poppins", sans-serif;
  font-size: 0.55rem;
  font-weight: var(--bolder-font);
  color: white;
}

.messageDivSuccess {
  background-color: #58BF6F;

}

.messageDivSuccess p {
  font-family: "Poppins", sans-serif;
  font-size: 10.5px;
  font-weight: var(--bold-font);
  color: white;
}

/* .messageDiv p {} */
.errorMessageIconDiv {
  height: 100%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
  margin-right: 5px;
}

.warningIcon {
  font-size: 0.8rem;
  color: white;
  padding: 0px;
  margin: 0px;
}

/* ________________________________________________ */

.formTagContainer {
  grid-area: formTagContainer;
  width: 85%;
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 30% 30% 10% 30%;
  grid-template-areas:
    "emailAddressWrapper emailAddressWrapper "
    "passwordWrapper passwordWrapper"
    "passForgetWrapper passForgetWrapper"
    "submitBtnWrapper submitBtnWrapper";
  justify-content: center;
  align-items: center;
  margin: auto;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* border: 1px solid green; */
}

.emailAddressWrapper {
  grid-area: emailAddressWrapper;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
}

.emailAddressContainer {
  width: 100%;
  height: 80%;
  background-color: var(--box-bg-tertiary);
  border-radius: 15px;
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    "iconDiv labelDiv"
    "iconDiv inputDiv";
  box-sizing: border-box;

}

.passwordWrapper {
  grid-area: passwordWrapper;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
}

.passwordContainer {
  width: 100%;
  height: 80%;
  background-color: var(--box-bg-tertiary);
  border-radius: 15px;
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    "iconDiv labelDiv"
    "iconDiv inputDiv";
}


.passForgetWrapper {
  grid-area: passForgetWrapper;
  position: relative;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}


.forgotPassLinkText {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 0.6rem;
  font-weight: var(--boldest-font);
  color: var(--ui-scheme-color);
  margin-right: 2%;
  white-space: nowrap;
}

.iconDiv {
  grid-area: iconDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.fieldIcon {
  color: var(--field-icon-color);
  font-size: 1.5rem;
}

.labelDiv {
  grid-area: labelDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.labelDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 0.65rem;
  font-weight: var(--bolder-font);
  color: var(--secondary-font-color);
  margin-top: 5%;
}

.inputDiv {
  grid-area: inputDiv;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 2%;
  box-sizing: border-box;
}

.inputDiv input {
  width: 90%;
  height: 60%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  background: none;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-size: 0.65rem;
  font-weight: var(--bolder-font);
  color: var(--primary-font-color);
  letter-spacing: 0.3px;

}

.passwordVisibilityIconDiv {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* border: 1px solid red; */
}

.passVisibilityIcon {
  aspect-ratio: 1/1;
  height: 60%;
  cursor: pointer;
}

::-ms-reveal {
  display: none;
}

.focusFieldStyle {
  border: 2px solid var(--ui-scheme-color);
}

input:focus {
  /* border-bottom: 1px solid #002A9A; */

}

.submitBtnWrapper {
  grid-area: submitBtnWrapper;
  position: relative;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.demoLoginDiv {
  position: absolute;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  left: 0;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  /* border: 1px solid skyblue; */
  cursor: pointer;
}

.demoLoginDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 0.65rem;
  font-weight: var(--bold-font);
  color: var(--ui-scheme-color);
  text-decoration: underline white;
}

.submitBtn {
  width: 50%;
  height: 40%;
  border: none;
  background-color: var(--submit-btn-color);
  border-radius: 0.8rem;
  font-family: "Poppins", sans-serif;
  font-size: 0.7rem;
  font-weight: var(--bold-font);
  color: white;
  letter-spacing: 0.5px;
  /* outline: #AED2FF; */
  cursor: pointer;
}


/* ________________________________________________ */

.formFooterWrapper {
  grid-area: formFooterWrapper;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* border: 1px solid red; */
}

.formFooterWrapper p {
  font-family: "Poppins", sans-serif;
  font-size: 0.6rem;
  font-weight: var(--bolder-font);
  color: var(--primary-font-color);
}

.spanText {
  font-family: "Poppins", sans-serif;
  font-size: 0.6rem;
  font-weight: var(--bolder-font);
  color: var(--ui-scheme-color);
  margin-left: 2px;
  text-decoration: none;
}



@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .signInPage {
    /* border: 1px solid red; */
    width: 100%;
    height: 100%;
    display: flex;
  }

  .appBioSection {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  /* ____________________________________________________ */

  /* ______________ */
  .svgWrapper {
    width: 100%;
    height: 70%;
    /* border: 1px solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* ------------------------------------- */
  .svgContainer {
    width: 100%;
    height: 100%;
    /* border: 1px solid green; */
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    grid-template-rows: 20% 20% 20% 20% 20%;
    grid-template-areas:
      " pdfSvgWrapper  docSvgWrapper cardSvgWrapper"
      " pdfSvgWrapper  docSvgWrapper cardSvgWrapper"
      " pdfSvgWrapper  phoneSvgWrapper cardSvgWrapper"
      " passSvgWrapper  phoneSvgWrapper cardSvgWrapper"
      " passSvgWrapper  phoneSvgWrapper cardSvgWrapper";
  }

  .pdfSvg {
    position: absolute;
    width: 80%;
    height: unset;
    aspect-ratio: 1/1;
    top: 20%;
    left: 0%;
  }

  .docsSvg {
    position: absolute;
    width: 80%;
    height: unset;
    aspect-ratio: 1/1;
    top: 0%;
  }

  .passSvg {
    position: absolute;
    width: 80%;
    height: unset;
    aspect-ratio: 1/1;
    bottom: 0%;
  }

  .cardSvg {
    position: absolute;
    width: 120%;
    aspect-ratio: 1/1;
    top: 2%;
    right: -20%;
  }

  .phoneSvg {
    position: absolute;
    width: 100%;
    height: unset;
    aspect-ratio: 1/1;
    bottom: -5%;
  }

  .docSvgWrapper {
    position: relative;
    grid-area: docSvgWrapper;
    width: 100%;
    height: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .pdfSvgWrapper {
    position: relative;
    grid-area: pdfSvgWrapper;
    width: 100%;
    height: 100%;
    /* background-color: blue; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 25%;
  }

  .cardSvgWrapper {
    position: relative;
    grid-area: cardSvgWrapper;
    width: 100%;
    height: 100%;
    /* background-color: green; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
  }



  .passSvgWrapper {
    position: relative;
    grid-area: passSvgWrapper;
    width: 100%;
    height: 100%;
    /* background-color: rosybrown; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .phoneSvgWrapper {
    position: relative;
    grid-area: phoneSvgWrapper;
    width: 100%;
    height: 100%;
    /* background-color: yellow; */
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  /* ------------------------------------- */



  .bioTextWrapper {
    width: 100%;
    height: 30%;
    /* border: 1px solid orange; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .textDiv1 {
    width: 100%;
    height: 25%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .textDiv1 p {
    font-family: "Poppins", sans-serif;
    font-size: 1.2rem;
    font-size: 1.6rem;
    font-size: clamp(1.2rem, 0.3999999999999997rem + 4.000000000000002vw, 1.6rem) !important;
    font-weight: var(--boldest-font);
    color: var(--primary-font-color);
  }

  .textDiv2 {
    width: 90%;
    height: 25%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .textDiv2 p {
    font-family: "Poppins", sans-serif;
    font-size: 0.7rem;
    font-size: 0.9rem;
    font-size: clamp(0.7rem, 0.2999999999999998rem + 2.000000000000001vw, 0.9rem) !important;
    font-size: clamp(0.6rem, 0.19999999999999984rem + 2.000000000000001vw, 0.8rem) !important;

    font-weight: var(--bolder-font);
    color: #6A82A7;

  }

  .textDiv3 {
    width: 90%;
    height: 25%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .textDiv3 p {
    font-family: "Poppins", sans-serif;
    font-size: clamp(0.6rem, 0.19999999999999984rem + 2.000000000000001vw, 0.8rem) !important;
    color: #6A82A7;
    font-weight: var(--bolder-font);
  }

  .textDiv4 {
    width: 100%;
    height: 25%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
  }

  .bioSpanText {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-size: 0.5rem;
    /* font-size: 0.9rem; */
    /* font-size: clamp(0.7rem, 0.2999999999999998rem + 2.000000000000001vw, 0.9rem) !important; */
    font-size: clamp(0.6rem, 0.19999999999999984rem + 2.000000000000001vw, 0.8rem) !important;

    font-weight: var(--bolder-font);
    color: var(--ui-scheme-color);
  }

  .textDiv4 p {
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem;
    font-size: 0.7rem;
    font-size: 0.9rem;
    font-size: 0.5rem;

    /* font-size: clamp(0.7rem, 0.2999999999999998rem + 2.000000000000001vw, 0.9rem) !important; */
    font-size: clamp(0.6rem, 0.19999999999999984rem + 2.000000000000001vw, 0.8rem) !important;

    font-weight: var(--bolder-font);
    color: #6A82A7;

  }

  /* _________________________________________________ */

  .signInformSection {
    position: relative;
    width: 100%;
    height: 100%;
    /* display: none; */
  }

  .formPageWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    /* border: 1px solid orange; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: scroll;
  }

  .formPageWrapper::-webkit-scrollbar {
    display: none;
  }

  .formWrapper {
    margin-top: 20%;
    position: relative;
    width: 85%;
    aspect-ratio: 2/2.5;
    border-radius: 2.5rem;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 20% 10% minmax(0, 1fr) 8%;
    grid-template-rows: 20% auto minmax(0, 1fr) 8%;
    grid-template-areas:
      "formHeaderWrapper formHeaderWrapper"
      "formMessageWrapper formMessageWrapper "
      "formTagContainer formTagContainer"
      "formFooterWrapper formFooterWrapper";

    justify-content: center;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* border: 1px solid springgreen; */
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

  }

  /* ________________________________________________ */
  .formHeaderWrapper {
    grid-area: formHeaderWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid orange; */
  }

  .formLabelDiv {
    position: relative;
    width: 80%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
  }

  .formLabelDiv p {
    font-size: clamp(1.2rem, -0.40000000000000013rem + 8.0vw, 2.0rem) !important;
  }

  .formLabelDiv::after {
    position: absolute;
    content: '';
    width: 15%;
    height: 3px;
    border-radius: 20px;
    bottom: 0px;
  }

  /* ________________________________________________ */

  .formMessageWrapper {
    grid-area: formMessageWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* border: 1px solid blue; */
    box-sizing: border-box;
    padding-bottom: 2%;
  }

  .messageDiv {
    width: auto;
    max-width: 85%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 6px;
    padding: 1% 4% 1% 4%;
    /* margin-top: 3%; */
  }



  .messageDivError p {
    font-size: clamp(0.55rem, -0.1499999999999999rem + 3.4999999999999996vw, 0.9rem) !important;
  }


  .messageDivSuccess p {
    font-size: clamp(0.55rem, -0.1499999999999999rem + 3.4999999999999996vw, 0.9rem) !important;
  }

  /* .messageDiv p {} */
  .errorMessageIconDiv {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
    margin-right: 5px;
  }

  .warningIcon {
    font-size: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;
    padding: 0px;
    margin: 0px;
  }

  /* ________________________________________________ */

  .formTagContainer {
    grid-area: formTagContainer;
    width: 85%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 30% 30% 10% 30%;
    grid-template-areas:
      "emailAddressWrapper emailAddressWrapper "
      "passwordWrapper passwordWrapper"
      "passForgetWrapper passForgetWrapper"
      "submitBtnWrapper submitBtnWrapper";
    justify-content: center;
    align-items: center;
    margin: auto;
    webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* border: 1px solid green; */
  }

  .emailAddressWrapper {
    grid-area: emailAddressWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
  }

  .emailAddressContainer {
    width: 100%;
    height: 80%;
    border-radius: 15px;
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
      "iconDiv labelDiv"
      "iconDiv inputDiv";
    box-sizing: border-box;

  }

  .passwordWrapper {
    grid-area: passwordWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
  }

  .passwordContainer {
    width: 100%;
    height: 80%;
    border-radius: 15px;
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
      "iconDiv labelDiv"
      "iconDiv inputDiv";
  }


  .passForgetWrapper {
    grid-area: passForgetWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }


  .forgotPassLinkText {
    font-size: clamp(0.6rem, -0.20000000000000007rem + 4.0vw, 1.0rem) !important;
    margin-right: 2%;
    white-space: nowrap;
  }


  .iconDiv {
    grid-area: iconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fieldIcon {
    font-size: clamp(2.1rem, 3.5000000000000004rem + -7.000000000000002vw, 1.4rem) !important;
  }

  .labelDiv {
    grid-area: labelDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .labelDiv p {
    font-size: clamp(0.6rem, -1.1102230246251565e-16rem + 3.0000000000000004vw, 0.9rem) !important;
    margin-top: 5%;
  }

  .inputDiv {
    grid-area: inputDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 2%;
    box-sizing: border-box;
  }

  .inputDiv input {
    width: 90%;
    height: 60%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    background: none;
    outline: none;
    font-size: clamp(0.6rem, -1.1102230246251565e-16rem + 3.0000000000000004vw, 0.9rem) !important;
    letter-spacing: 0.3px;
  }

  input:focus {
    /* border-bottom: 1px solid #002A9A; */

  }

  .submitBtnWrapper {
    grid-area: submitBtnWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submitBtn {
    width: 55%;
    height: 40%;
    border: none;
    border-radius: 15px;
    font-size: clamp(0.7rem, 0.09999999999999987rem + 3.0000000000000004vw, 1.0rem) !important;
    letter-spacing: 0.5px;
    /* outline: #AED2FF; */
  }


  /* ________________________________________________ */

  .formFooterWrapper {
    grid-area: formFooterWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* border: 1px solid red; */
  }

  .formFooterWrapper p {
    font-size: clamp(0.6rem, -1.1102230246251565e-16rem + 3.0000000000000004vw, 0.9rem) !important;
    white-space: nowrap;
  }

  .spanText {
    font-size: clamp(0.6rem, -1.1102230246251565e-16rem + 3.0000000000000004vw, 0.9rem) !important;
    margin-left: 1%;
  }

  .demoLoginDiv p {
    font-size: clamp(0.65rem, 0.15000000000000002rem + 2.5vw, 0.9rem) !important;
  }

}