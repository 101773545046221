.loginsList {
  position: relative;
  width: 99.8%;
  height: 100%;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  z-index: 45;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*  > FOR DELETE MODAL */
.modalWrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25% !important;
  height: unset !important;
  aspect-ratio: 3/2;
  /* background-color: white; */
  /* background-color: aquamarine; */
  border-radius: 10px;
  top: 5% !important;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 99;
}

.contentContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  scroll-behavior: smooth;
  overflow-y: scroll;
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  scrollbar-width: none;
  /* firefox hide scrollbar  */
  -ms-overflow-style: none;
  /* firefox hide scrollbar  */
  /* border: 1px solid skyblue; */
}

.blurContainer {
  opacity: 0.1;
  /* border: 1px solid salmon; */
}

.contentContainerClose {
  position: relative;
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  scroll-behavior: smooth;
  overflow-y: scroll;
  scroll-behavior: smooth;
  /* border: 1px solid green; */
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 100px;
  box-sizing: border-box;

  scrollbar-width: none;
  /* firefox hide scrollbar  */
  -ms-overflow-style: none;
  /* firefox hide scrollbar  */

}



/*_____________________*/

/* .fullContentCard {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid black;
  background-color: white;
} */

/*_____________________*/

.contentContainer::-webkit-scrollbar {
  background-color: rgba(176, 32, 32, 0);
  height: 4px;
  width: 3px;
  display: none;

}

.contentContainer::-webkit-scrollbar-track {
  background-color: rgb(231, 228, 228);
  background-color: rgba(231, 228, 228, 0.062);
  width: 2px;
}

.contentContainer::-webkit-scrollbar-thumb {
  background-color: var(--ui-scheme-color);
  border-radius: 10px;
}

.contentContainerClose::-webkit-scrollbar {
  display: none;
  background-color: rgba(176, 32, 32, 0);
  height: 4px;
  width: 3px;
}

.contentContainerClose::-webkit-scrollbar-track {
  background-color: rgb(231, 228, 228);
  background-color: rgba(231, 228, 228, 0.062);
  width: 2px;
}

.contentContainerClose::-webkit-scrollbar-thumb {
  background-color: var(--ui-scheme-color);
  border-radius: 10px;
}



@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

  .modalWrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80% !important;
    height: unset !important;
    aspect-ratio: 3/2;
    border-radius: 10px;
    top: 20% !important;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 99;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    z-index: 99;
  }

  .loginsList {
    position: relative;
    width: 99.5%;
    height: 100%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    z-index: 45;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-top: 0%;
  }

  .contentContainer {
    padding-left: 0;
    padding-right: 0;
    /* border: 1px solid white; */
    height: 90%;
    margin-top: 10%;
    transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
    padding-top: 0px;
    /* border: 1px solid skyblue; */
  }

  .contentContainerClose {
    height: 0%;
    width: 100%;
    margin-top: 10%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    scroll-behavior: smooth;
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding-top: 0px;
    padding-bottom: 0px;
    box-sizing: border-box;
    padding-left: 0;
    padding-right: 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
    transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
    /* border: 1px solid skyblue; */
  }

}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {

  .loginsList {
    position: relative;
    width: 99.8%;
    height: 100%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    z-index: 45;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0;
  }

  .contentContainer {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    scroll-behavior: smooth;
    overflow-y: scroll;
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    scrollbar-width: none;
    /* firefox hide scrollbar  */
    -ms-overflow-style: none;
    /* firefox hide scrollbar  */
    /* border: 1px solid skyblue; */
  }

  .contentContainerClose {
    position: relative;
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    scroll-behavior: smooth;
    overflow-y: scroll;
    scroll-behavior: smooth;
    /* border: 1px solid green; */
    padding-left: 0;
    padding-right: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-sizing: border-box;
    scrollbar-width: none;
    /* firefox hide scrollbar  */
    -ms-overflow-style: none;
    /* firefox hide scrollbar  */
    /* border: 1px solid skyblue; */

  }
}