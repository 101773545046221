.activityList {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll !important;
    /* border: 1px solid blue; */
    box-sizing: border-box;
    padding: 20px 10px 20px 10px;
    scrollbar-width: none;
    /* -ms-overflow-style: none; */
}

.activityList::-webkit-scrollbar {
    background-color: rgba(176, 32, 32, 0);
    height: 4px;
    width: 0.15rem;
}

.activityList::-webkit-scrollbar-track {
    background-color: rgb(231, 228, 228);
    background-color: rgba(231, 228, 228, 0.062);
    width: 2px;
}

.activityList::-webkit-scrollbar-thumb {
    background-color: var(--ui-scheme-color);
    border-radius: 10px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .activityList {
        height: 95%;
        /* padding: 5% 0% 10% 0%; */
        padding: 0% 0% 5% 0%;
        /* border: 1px solid skyblue; */
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {


    .activityList {
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        position: relative;
        height: 95%;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        scroll-behavior: smooth;
        overflow-y: scroll;
        padding-top: unset;
        padding-left: unset;
        padding-bottom: unset;
        box-sizing: border-box;
        scrollbar-width: none;
        /* firefox hide scrollbar  */
        -ms-overflow-style: none;
        /* firefox hide scrollbar  */
        /* border: 1px solid rebeccapurple; */
        margin: 0px;
        padding: 0px;
        margin-top: 5%;
        margin-bottom: 0px;

    }


}