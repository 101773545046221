.favLoginListWrapper {
    position: relative;
    width: 99.8%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    z-index: 45;
    webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* border: 1px solid red; */
}

.contentContainer {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    scroll-behavior: smooth;
    overflow-y: scroll;
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    scrollbar-width: none;
    /* firefox hide scrollbar  */
    -ms-overflow-style: none;
    /* firefox hide scrollbar  */

}

.contentContainerClose {
    position: relative;
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    scroll-behavior: smooth;
    overflow-y: scroll;
    scroll-behavior: smooth;
    /* border: 1px solid green; */
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 100px;
    box-sizing: border-box;
    scrollbar-width: none;
    /* firefox hide scrollbar  */
    -ms-overflow-style: none;
    /* firefox hide scrollbar  */
    /* border: 1px solid red; */
}


.contentContainerClose::-webkit-scrollbar {
    display: none;
    background-color: rgba(176, 32, 32, 0);
    height: 4px;
    width: 3px;
    width: 0.15rem;

}

.contentContainer::-webkit-scrollbar {
    background-color: rgba(176, 32, 32, 0);
    height: 4px;
    /* width: 3px; */
    width: 0.15rem;

}

.contentContainer::-webkit-scrollbar-track {
    background-color: rgb(231, 228, 228);
    background-color: rgba(231, 228, 228, 0.062);
    /* width: 2px; */
    width: 0.15rem;

}

.contentContainer::-webkit-scrollbar-thumb {
    background-color: var(--ui-scheme-color);
    border-radius: 10px;
}


@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {


    .favLoginListWrapper {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        z-index: 45;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        /* border: 0.02rem solid white; */
    }

    .contentContainer {
        padding-left: 0;
        padding-right: 0;
        height: 95%;
        margin-top: 5%;
        /* transition: all 10s cubic-bezier(0.25, 0.8, 0.25, 1); */
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        /* border: 1px solid skyblue; */
    }

    .contentContainerClose {
        height: 0%;
        margin-top: 5%;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        scroll-behavior: smooth;
        overflow-y: scroll;
        scroll-behavior: smooth;
        padding-top: 0px;
        padding-bottom: 0px;
        box-sizing: border-box;
        padding-left: 0;
        padding-right: 0;
        scrollbar-width: none;
        -ms-overflow-style: none;
        /* transition: all 10s cubic-bezier(0.25, 0.8, 0.25, 1); */
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        overflow: hidden;
        /* border: 1px solid skyblue; */
    }

}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {

    .favLoginListWrapper {
        position: relative;
        width: 99.8%;
        height: 100%;
        /* border: 1px solid red; */
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        z-index: 45;
        webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .contentContainer {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        scroll-behavior: smooth;
        overflow-y: scroll;
        /* padding-top: 10px; */
        /* padding-left: 20px; */
        /* padding-bottom: 20px; */
        box-sizing: border-box;
        scrollbar-width: none;
        /* firefox hide scrollbar  */
        -ms-overflow-style: none;
        /* firefox hide scrollbar  */
        /* border: 1px solid black; */
    }

    .contentContainerClose {
        position: relative;
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        scroll-behavior: smooth;
        overflow-y: scroll;
        scroll-behavior: smooth;
        /* border: 1px solid green; */
        padding-left: 0;
        padding-right: 0;
        padding-top: 2rem;
        padding-bottom: 2rem;
        box-sizing: border-box;

        scrollbar-width: none;
        /* firefox hide scrollbar  */
        -ms-overflow-style: none;
        /* firefox hide scrollbar  */

    }
}