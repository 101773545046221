.documentFullScreenWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    border-radius: 10px;
    /* border: 1px solid rgba(255, 255, 255, 0.18); */
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.documentFullScreenWrapperClose {
    position: absolute;
    width: 0%;
    height: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    border-radius: 10px;
    /* border: 1px solid rgba(255, 255, 255, 0.18); */
    overflow: hidden;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;

}


.imageContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: black;
}

.imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.headerContainer {
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: var(--box-bg-primary);

    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    /* border: 1px solid red; */
}

.headerContainerClose {
    position: absolute;
    top: -50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: var(--box-bg-primary);

    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

}

.backBtnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 100%;
    /* border: 1px solid red; */
}

.backBtnDiv {
    width: 70%;
    height: 50%;
    cursor: pointer;
    /* border: 1px solid black; */
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.favBtnContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 90%;
    height: 100%;
    /* border: 1px solid red; */
}

.favBtnDiv {
    height: 55%;
    aspect-ratio: 1/1;
    display: flex;
    /* border: 1px solid blue; */
    justify-content: center;
    align-items: center;
    margin-right: 2%;
    cursor: pointer;
}

.footerContainer {
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: var(--box-bg-primary);

    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

}

.footerContainerClose {
    position: absolute;
    bottom: -50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: var(--box-bg-primary);

    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

}

.titleInputDiv {
    width: 70%;
    height: 100%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
}

.titleInputDiv input {
    width: 70%;
    height: 70%;
    border-bottom: 1px solid #7E8DA4;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    background: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: var(--bolder-font);
    color: var(--primary-font-color);

    padding-left: 10%;
}

.titleInputDiv p {
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: var(--bolder-font);
    color: var(--primary-font-color);

    margin-left: 10%;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.titleCrudBtnContainer {
    width: 30%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5%;
}

.titleSaveBtnDiv {
    height: 70%;
    aspect-ratio: 1/1;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10%;
}

.titleCrudIcon {
    font-size: 20px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .backBtnContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 30%;
        height: 100%;
        /* border: 1px solid red; */
        padding-left: 2%;
    }

    .backBtnDiv {
        width: 60%;
        height: 70%;
        aspect-ratio: 2/1;
        /* border: 1px solid black; */
    }

    .favBtnContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 70%;
        height: 100%;
        /* border: 1px solid red; */
    }
}