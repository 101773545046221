.componentWrapper {
    position: absolute;
    width: 26%;
    aspect-ratio: 2/2.5;
    /* height: 70%;
    min-height: 70%; */
    /* background-color: var(--box-bg-primary); */
    background-color: var(--box-bg-tertiary-drk);
    display: grid;
    grid-template-areas:
        "closeWrapper"
        "logoSearchWrapper"
        "logoListWrapper";
    grid-template-columns: 100%;
    grid-template-rows: 8% 10% 82%;
    z-index: 999;
    border-radius: 10px;
    /* border: 1px solid red; */
    bottom: 30px;
    left: 0;
    right: 0;
    top: 10%;
    top: 0%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: var(--logoComp-wrapper-box-shadow);
    /* position: relative; 
left: 50%;
transform: translateX(-50%); */
}


.closeWrapper {
    grid-area: closeWrapper;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    padding-right: 2%;
}

.closeIconDiv {
    position: relative;
    aspect-ratio: 1/1;
    height: 70%;
    border-radius: 50%;
    background-color: #93C2FF;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0px;
}

.saveIconDiv {
    position: relative;
    aspect-ratio: 1/1;
    height: 70%;
    border-radius: 50%;
    background-color: #58BF6F;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0px;
    margin-right: 1rem;

}

.closeIcon {
    position: relative;
    font-size: 13px;
    /* border: 1px solid black; */
    color: #002A9A;
    cursor: pointer;

}

.saveIcon {
    position: relative;
    font-size: 13px;
    /* border: 1px solid black; */
    color: white;
    cursor: pointer;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

    .componentWrapper {
        position: absolute;
        width: 80%;
        aspect-ratio: 2/2.5;
        display: grid;
        grid-template-areas:
            "closeWrapper"
            "logoSearchWrapper"
            "logoListWrapper";
        grid-template-columns: 100%;
        grid-template-rows: 8% 10% 82%;
        z-index: 999;
        border-radius: 10px;
        /* border: 1px solid red; */
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: unset;
        top: 20px;
    }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {

    .componentWrapper {
        position: absolute;
        width: 50%;
        aspect-ratio: 2/2.5;
        display: grid;
        grid-template-areas:
            "closeWrapper"
            "logoSearchWrapper"
            "logoListWrapper";
        grid-template-columns: 100%;
        grid-template-rows: 8% 10% 82%;
        z-index: 999;
        border-radius: 10px;
        /* border: 1px solid red; */
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: unset;
        top: 20px;
    }
}