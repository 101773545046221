@import "../../variable.modules.css";

.skeletonWrapper {
    position: relative;
    display: grid;
    grid-template-columns: 80px minmax(0, 1fr) minmax(0, 0.1fr);
    grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-areas:
        "logoWrapper titleWrapper favBtnWrapper"
        "logoWrapper usernameWrapper favBtnWrapper";
    align-items: center;
    width: 280px;
    height: 75px;
    aspect-ratio: 3.73/1 !important;
    border-radius: 0.9rem;
    margin: 15px 15px 15px 15px;
    background-color: var(--box-bg-primary);
    /* box-shadow: var(--list-wrapper-box-shadow); */
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0.5rem;
}


.logoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: logoWrapper;
    width: 100%;
    height: 100%;
    /* background-color: #D6D6D6; */
}


.logoDiv {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 88%;
    aspect-ratio: 1/1;
    border-radius: 10px;
    /* background-color: #D6D6D6; */
    background-color: var(--box-bg-tertiary);
    overflow: hidden;
}

.titleWrapper {
    grid-area: titleWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    /* background-color: #e8e8e4; */
    /* border: 1px solid red; */
    padding-left: 0.1rem;
}

.titleDiv {
    position: relative;
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    overflow: hidden;
    background-color: var(--box-bg-tertiary);
    border-radius: 0.3rem;
}


.usernameWrapper {
    grid-area: usernameWrapper;
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    /* background-color: #e8e8e4; */
    align-items: center;
    overflow: hidden;
    /* background-color: #e8e8e4; */
    /* border: 1px solid red; */
    padding-left: 0.1rem;
}

.usernameDiv {
    position: relative;
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    overflow: hidden;
    background-color: var(--box-bg-tertiary);
    border-radius: 0.3rem;
}

.favBtnWrapper {
    grid-area: favBtnWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shimmerWrapper {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    overflow: hidden;
    animation: loading 1.55s infinite;
}

.shimmer {
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--shimmer-color);
    border-radius: 3px;
    background-repeat: no-repeat;
}

@keyframes loading {
    0% {
        transform: translateX(-150%);
    }

    50% {
        transform: translateX(-60%);
    }

    100% {
        transform: translateX(150%);
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {


    .skeletonWrapper {
        position: relative;
        display: grid;
        grid-template-columns: 80px minmax(0, 1fr) minmax(0, 0.2fr);
        grid-template-columns: 25% minmax(0, 1fr) minmax(0, 0.1fr);
        grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
        grid-template-areas:
            "logoWrapper titleWrapper favBtnWrapper"
            "logoWrapper usernameWrapper favBtnWrapper";
        width: 83% !important;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        /* border: 1px solid red; */
        margin: 0.80rem auto 0.80rem auto;
        border-radius: 18px;
    }



}