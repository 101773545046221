.subCardWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 33% 33% 33%;
    grid-template-areas:
        "cardHolderWrapper cardHolderWrapper"
        "cardNumberWrapper cardNumberWrapper"
        "dobDateWrapper expiryDateWrapper";
    padding-bottom: 0%;
    padding-top: 0%;
    box-sizing: border-box;
    justify-items: center;
}

/* 
> We are using input instead of div for textField
> because div will expand when using white-space:nowrap
> but using input(readOnly) gives desired result
*/

.textInputCommonStyle {
    border: none;
    outline: none;
    background: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Poppins", sans-serif;
    color: var(--primary-font-color);
    font-weight: var(--bold-font);
}


/* _____________________________________________ */

.cardHolderWrapper {
    grid-area: cardHolderWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid orange; */
}


.cardHolderContainer {
    height: 70%;
    /* border: 1px solid black; */
    display: grid;
    align-items: center;
    grid-template-columns: 20% 60% 20%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
        "cardHolderIconDiv cardHolderLabelTextDiv cardVenderIconContainer  "
        "cardHolderIconDiv cardHolderInputDiv cardVenderIconContainer";
    background-color: var(--box-bg-tertiary);
    border-radius: 10px;
}

.cardHolderIconDiv {
    grid-area: cardHolderIconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardHolderIcon {
    font-size: 1.8rem;
    color: var(--field-icon-color);
}

.cardHolderLabelTextDiv {
    grid-area: cardHolderLabelTextDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-items: center;
    align-items: center;
}

.cardHolderLabelTextDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--secondary-font-color);
}

.cardHolderInputDiv {
    grid-area: cardHolderInputDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    /* border: 1px solid salmon; */
}

.cardHolderTextField {
    width: 100%;
    height: 100%;
    /* border: 1px solid skyblue; */
}

.cardHolderTextFieldFont {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bold-font);
    color: var(--primary-font-color);
}


/* _____________________________________________________ */
.cardVenderIconContainer {
    grid-area: cardVenderIconContainer;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cardVenderIconDiv {
    aspect-ratio: 1/1;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.cardVenderLogo {
    /* height: 70%; */
    width: 80%;
    /* border: 1px solid black; */
}

/* _____________________________________________ */
.cardNumberWrapper {
    grid-area: cardNumberWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid green; */
}

.cardNumberContainer {
    height: 70%;
    /* border: 1px solid black; */
    display: grid;
    align-items: center;

    grid-template-columns: 20% 60% 20%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
        "cardNumberIconDiv cardNumberLabelTextDiv cardVenderIconContainer  "
        "cardNumberIconDiv cardNumberInputDiv cardVenderIconContainer";
    background-color: var(--box-bg-tertiary);
    border-radius: 10px;
}


.cardNumberIconDiv {
    grid-area: cardNumberIconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardNumIcon {
    font-size: 1.5rem;
    color: var(--field-icon-color);

}

.cardNumberLabelTextDiv {
    grid-area: cardNumberLabelTextDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-items: center;
    align-items: center;
}

.cardNumberLabelTextDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--secondary-font-color);
}

.cardNumberInputDiv {
    grid-area: cardNumberInputDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
}

.cardNumberTextField {
    width: 100%;
    height: 100%;
    /* border: 1px solid skyblue; */

}

.cardNumberTextFieldFont {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--primary-font-color);
}



/* _____________________________________________ */

.dobDateWrapper {
    grid-area: dobDateWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: flex-start;
}

.dobDateContainer {
    height: 70%;
    width: 90%;
    /* border: 1px solid black; */
    display: grid;
    align-items: center;
    grid-template-columns: 30% 70%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
        "dobDateIconDiv dobLabelTextDiv"
        "dobDateIconDiv dobInputDiv";
    background-color: var(--box-bg-tertiary);
    border-radius: 10px;
}

.dobDateIconDiv {
    grid-area: dobDateIconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.dobDateIcon {
    font-size: 1.5rem;
    color: var(--field-icon-color);
}

.dobLabelTextDiv {
    grid-area: dobLabelTextDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dobLabelTextDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--secondary-font-color);
}

.dobTextField {
    width: 100%;
    height: 100%;
    /* border: 1px solid skyblue; */
}

.dobInputDiv {
    grid-area: dobInputDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dobTextFieldFont {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--primary-font-color);

}

/* _____________________________________________ */

.expiryDateWrapper {
    grid-area: expiryDateWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: flex-end;
}

.expiryDateContainer {
    height: 70%;
    width: 90%;
    /* border: 1px solid black; */
    display: grid;
    align-items: center;
    grid-template-columns: 30% 70%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
        "expiryDateIconDiv expiryDateLabelTextDiv"
        "expiryDateIconDiv expiryDateInputDiv";
    background-color: var(--box-bg-tertiary);
    border-radius: 10px;
}

.expiryDateIconDiv {
    grid-area: expiryDateIconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.expiryDateIcon {
    font-size: 1.5rem;
    color: var(--field-icon-color);
}

.expiryDateLabelTextDiv {
    grid-area: expiryDateLabelTextDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.expiryDateLabelTextDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--secondary-font-color);
}

.expiryDateInputDiv {
    grid-area: expiryDateInputDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.expiryDateTextField {
    width: 100%;
    height: 100%;
    /* border: 1px solid skyblue; */
}

.expiryDateTextFieldFont {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--primary-font-color);
}


@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

    .subCardWrapper {
        position: relative;
        /* border: 1px solid red; */
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 33% 33% 33%;
        grid-template-areas:
            "cardHolderWrapper cardHolderWrapper"
            "cardNumberWrapper cardNumberWrapper"
            "dobDateWrapper expiryDateWrapper";
        padding-bottom: 0%;
        padding-top: 0%;
        box-sizing: border-box;
        justify-items: center;
    }

    /* _____________________________________________ */

    .cardHolderWrapper {
        width: 90%;
        height: 100%;
    }

    .cardHolderContainer {
        border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;
    }

    .cardHolderLabelTextDiv p {
        font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important;
    }

    .cardHolderTextFieldFont {
        font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important;
    }

    .cardHolderIcon {
        font-size: clamp(1.6rem, 4.440892098500626e-16rem + 7.999999999999999vw, 2.4rem) !important;
    }

    /* _____________________________________________________ */


    /* _____________________________________________ */
    .cardNumberWrapper {
        width: 90%;
        height: 100%;
    }

    .cardNumberContainer {
        border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;
    }

    .cardNumberLabelTextDiv p {
        font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important;
    }

    .cardNumberTextFieldFont {
        font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important;
    }

    .cardNumIcon {
        font-size: clamp(1.3rem, 0.10000000000000031rem + 5.999999999999998vw, 1.9rem) !important;
    }

    /* _____________________________________________ */

    .dobDateWrapper {
        padding-left: 10%;
    }

    .dobDateContainer {
        border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;
    }


    .dobLabelTextDiv p {
        font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important;
    }

    .dobTextFieldFont {
        font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important;
    }

    .dobDateIcon {
        font-size: clamp(1.3rem, 0.10000000000000031rem + 5.999999999999998vw, 1.9rem) !important;
    }


    /* _____________________________________________ */

    .expiryDateWrapper {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .expiryDateContainer {
        border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;
    }

    .expiryDateLabelTextDiv p {
        font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important;
    }

    .cvvTextFieldFont {
        font-size: clamp(0.75rem, 0.25rem + 2.5vw, 1.0rem) !important;
    }

    .expiryDateIcon {
        font-size: clamp(1.3rem, 0.10000000000000031rem + 5.999999999999998vw, 1.9rem) !important;
    }
}