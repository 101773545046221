@import "../../variable.modules.css";

.activityWrapperOuter {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: auto;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: flex-end;
    box-sizing: border-box;
    margin-top: 2%;
    margin-left: 5%;
    padding-top: 0%;
    padding-bottom: 5%;
    border-bottom: 1px solid var(--box-bg-secondary);
    /* background-color: var(--box-bg-secondary); */
    scroll-snap-align: start;
}

.activityWrapperOuter::before {
    position: absolute;
    left: 7%;
    top: 0%;
    content: "";
    background-color: #cacdd2;
    width: 1px;
    height: 98%;
    border-radius: 10px;
    z-index: 1;
}


.dateTimeTaskTypeWrapper {
    width: 100%;
    height: 70px;
    /* border: 1px solid black; */
    display: grid;
    grid-template-columns: 20% 30% 30%;
    grid-template-rows: 40% 60%;
    grid-template-areas:
        "dateWrapper timeWrapper timeWrapper"
        "dateWrapper typeWrapper taskWrapper";
}

/* ___________________________________________ */
.dateWrapper {
    position: relative;
    grid-area: dateWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 2;
}

.dateWrapper::after {
    position: absolute;
    right: 15%;
    content: "";
    background-color: var(--ui-scheme-color);
    width: 2.5px;
    height: 80%;
    border-radius: 10px;
}

.dateContainer {
    position: relative;
    width: 68%;
    height: 100%;
    background-color: var(--box-bg-primary);
    border-radius: 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--dashboard-activity-date-shadow);
    overflow: hidden;
    /* border: 1px solid red; */
    padding: 5%;
}

.dateDiv {
    position: relative;
    display: flex;
    width: 80%;
    height: 80%;
    /* border: 1px solid red; */
    overflow: hidden;
    border-radius: 0.75rem;
}


/* ___________________________________________ */


.timeWrapper {
    grid-area: timeWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.timeContainer {
    position: relative;
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
}

.timeContainer p {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: var(--bolder-font);
    color: var(--primary-font-color);
    margin: 0px;
    padding: 0px;
}

/* ___________________________________________ */

.typeWrapper {
    grid-area: typeWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid orange; */
    /* background-color: red; */
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.typeContainer {
    position: relative;
    width: 80%;
    height: 70%;
    background-color: var(--box-bg-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    box-shadow: var(--dashboard-activity-type-shadow);
    overflow: hidden;
    padding: 5%;

}

.typeDiv {
    position: relative;
    display: flex;
    width: 80%;
    height: 70%;
    /* border: 1px solid red; */
    overflow: hidden;
    border-radius: 0.3rem;
}

.typeContainer p {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: var(--bolder-font);
    color: var(--primary-font-color);
    margin: 0px;
    padding: 0px;
}

/* ___________________________________________ */

.taskWrapper {
    grid-area: taskWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.taskContainer {
    position: relative;
    width: 80%;
    height: 70%;
    background-color: var(--box-bg-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    box-shadow: var(--dashboard-activity-task-shadow);
    /* border: 2px solid #D64339; */
    box-sizing: border-box;
    overflow: hidden;
    padding: 5%;
    /* border: 1px solid red; */
}

.taskDiv {
    position: relative;
    display: flex;
    width: 80%;
    height: 70%;
    overflow: hidden;
    border-radius: 0.3rem;
}




/* ___________________________________________ */

.innerBox {
    position: relative;
    width: 78%;
    height: 60px;
    background-color: red;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* border: 1px solid black; */
    border-radius: 15px;
    margin-top: 8px;
    margin-right: 5.5%;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}

.innerBox::before {
    position: absolute;
    left: 0px;
    content: "";
    background-color: #002A9A;
    width: 2.5px;
    height: 40%;
    border-radius: 10px;
}

.fieldWrapper {
    position: relative;
    width: 78%;
    height: 60px;
    background-color: var(--box-bg-primary);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    margin-top: 8px;
    margin-right: 5.5%;
    box-shadow: var(--dashboard-activity-field-shadow);
    /* border: 1px solid red; */
    overflow: hidden;
    padding: 0.5rem;
}

.oldValDiv {
    position: relative;
    width: 80%;
    height: 60%;
    /* border: 1px solid white; */
    border-radius: 0.3rem;
    overflow: hidden;
}

.newValDiv {
    position: relative;
    width: 80%;
    height: 60%;
    /* border: 1px solid white; */
    margin-top: 2%;
    border-radius: 0.3rem;
    overflow: hidden;
}

.fieldWrapper::before {
    position: absolute;
    left: 0px;
    content: "";
    background-color: var(--ui-scheme-color);
    width: 2.5px;
    height: 40%;
    border-radius: 10px;
}