.favDocsList {
    position: relative;
    /* border: 1px solid blue; */
    width: 100%;
    height: 100%;
}

.contentContainer {
    height: 95%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    overflow-y: scroll;
    box-sizing: border-box;
    transform: perspective(1px) translateZ(0);
    backface-visibility: hidden;
    /* border: 1px solid black; */
    margin-top: 0%;
    padding-left: 20px;
    padding-right: 20px;
}




.contentContainer::-webkit-scrollbar {
    background-color: rgba(176, 32, 32, 0);
    height: 4px;
    width: 0.15rem;

}

.contentContainer::-webkit-scrollbar-track {
    background-color: rgb(231, 228, 228);
    background-color: rgba(231, 228, 228, 0.062);
    width: 2px;
  width: 0.15rem;

}

.contentContainer::-webkit-scrollbar-thumb {
    background-color: var(--ui-scheme-color);
    border-radius: 10px;
}


@media screen and (max-width: 650px) {
    .favDocsList {
        position: relative;
        width: 100%;
        height: 100%;
        /* border: 1px solid red; */
        display: flex;
        justify-content: center;
        align-content: center;
        z-index: 99;
        box-sizing: border-box;
    }

    .contentContainer {
        /* border: 1px solid black; */
        height: 95%;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        margin: 0;
        margin-top: 5%;
        list-style-type: none;
        padding-top: 5%;
        padding-bottom: 20%;
        padding-left: 5%;
        padding-right: 5%;



        box-sizing: border-box;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        transform: perspective(1px) translateZ(0);
        backface-visibility: hidden;
    }
}