.sidebarComponent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  background-color: var(--box-bg-primary);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 15% 80%;
  grid-template-areas:
    "logoContainer"
    "menuSection";
  align-items: center;
}

.logoSection {
  grid-area: logoContainer;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  padding-left: 10%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.appLogoContainer {
  position: relative;
  height: 80%;
  /* aspect-ratio: 1/1; */
  width: 80%;
  /* border: 1px solid blueviolet; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.applogoDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  aspect-ratio: 1/1;
  /* border: 1px solid red; */
}

.menuSection {
  position: relative;
  grid-area: menuSection;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 20px;
  z-index: 1;
  /* padding-left: 5%; */
}

.linkWrapper {
  width: 70%;
  height: 30px;
  /* border: 1px solid black; */
  margin: 20px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  z-index: 2;
  margin-left: 5%;
}

.sidebarIndicator {
  position: absolute;
  width: 70%;
  height: 30px;
  background-color: #aed2ff;
  background-color: var(--box-bg-secondary-drk);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-left: auto;
  border-radius: 8px;
  /* border: 1px solid black; */
  margin-left: 5%;
}

.linkIconContainer {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconDiv {
  width: 70%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
}

.linkTextDiv {
  /* border: 1px solid black; */
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.linkText {
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  /* font-weight:var(--bold-font); */
  color: var(--sidebar-link-text);
  /* color: #2f3e69; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1020px) {
  .sidebarComponent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 15% 80%;
    grid-template-areas:
      "logoContainer"
      "menuSection";
    align-items: center;
  }

  .logoSection {
    grid-area: logoContainer;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    padding-left: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .appLogoContainer {
    position: relative;
    width: 92%;
    height: unset;
    aspect-ratio: 1/1;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .applogoDiv {
    border-radius: 15px;
    width: 100%;
    height: unset;
    aspect-ratio: 1/1 !important;
    box-sizing: border-box;
    /* border: 1px solid red; */
  }

  .menuSection {
    grid-area: menuSection;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .linkWrapper {
    width: 75%;
    height: unset;
    aspect-ratio: 1/1;
    /* border: 1px solid black; */
    margin: 20px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sidebarIndicator {
    position: absolute;
    width: 75%;
    height: unset;
    aspect-ratio: 1/1;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-left: auto;
    border-radius: 10px;
  }

  .linkIconContainer {
    /* border: 1px solid black; */
    width: 80%;
    height: 100%;
  }

  .linkTextDiv {
    width: 80%;
    height: 100%;
    display: none;
    justify-content: flex-start;
    align-items: center;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .sidebarComponent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 15% 80%;
    grid-template-areas:
      "logoContainer"
      "menuSection";
    align-items: center;
  }

  .logoSection {
    grid-area: logoContainer;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    padding-left: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .appLogoContainer {
    position: relative;
    width: 92%;
    height: unset;
    aspect-ratio: 1/1;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .applogoDiv {
    border-radius: 15px;
    width: 100%;
    height: unset;
    aspect-ratio: 1/1 !important;
    box-sizing: border-box;
    /* border: 1px solid red; */
  }

  .menuSection {
    grid-area: menuSection;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .linkWrapper {
    width: 75%;
    height: unset;
    aspect-ratio: 1/1;
    /* border: 1px solid black; */
    margin: 20px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sidebarIndicator {
    position: absolute;
    width: 75%;
    height: unset;
    aspect-ratio: 1/1;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-left: auto;
    border-radius: 10px;
  }

  .linkIconContainer {
    /* border: 1px solid black; */
    width: 80%;
    height: 100%;
  }

  .linkTextDiv {
    width: 80%;
    height: 100%;
    display: none;
    justify-content: flex-start;
    align-items: center;
  }
}
