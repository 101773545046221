@import "../../variable.modules.css";

.acitveAccountPage {
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.activateAccountForm {
  position: relative;
  width: 20rem;
  max-width: 20rem;
  aspect-ratio: 3/2.5;
  border-radius: 2rem;
  background-color: var(--box-bg-primary);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 30% auto 25% 20%;
  grid-template-areas:
    "formHeaderWrapper formHeaderWrapper"
    "formMessageWrapper formMessageWrapper "
    "formActivateBtnWrapper formActivateBtnWrapper"
    "formFooterWrapper formFooterWrapper";
  box-shadow: var(--dashboard-activity-field-shadow);
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* border: 1px solid springgreen; */
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.formHeaderWrapper {
  grid-area: formHeaderWrapper;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.formLabelDiv {
  position: relative;
  width: 80%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
}

.formLabelDiv p {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: var(--boldest-font);
  color: var(--primary-font-color);
}

.formLabelDiv::after {
  position: absolute;
  content: '';
  background-color: var(--ui-scheme-color);
  width: 15%;
  height: 3px;
  border-radius: 20px;
  bottom: 0px;
}

.formMessageWrapper {
  grid-area: formMessageWrapper;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid blue; */
  box-sizing: border-box;
  padding-bottom: 2%;
}

.messageDiv {
  width: auto;
  max-width: 85%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 6px;
  padding: 1% 4% 1% 4%;
  /* margin-top: 3%; */

}

.messageDivError {
  background-color: #D64339;
}

.messageDivError p {
  font-family: "Poppins", sans-serif;
  font-size: 10.5px;
  font-weight: 550;
  color: white;
}

.messageDivSuccess {
  background-color: #58BF6F;

}

.messageDivSuccess p {
  font-family: "Poppins", sans-serif;
  font-size: 10.5px;
  font-weight: 550;
  color: white;
}

/* .messageDiv p {} */
.errorMessageIconDiv {
  width: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
  margin-right: 5px;
}

.warningIcon {
  font-size: 15px !;
  color: white;
  padding: 0px;
  margin: 0px;
}

.formActivateBtnWrapper {
  grid-area: formActivateBtnWrapper;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.activateBtn {
  width: 55%;
  height: 55%;
  border: none;
  background-color: var(--submit-btn-color);
  border-radius: 0.5rem;
  font-family: "Poppins", sans-serif;
  font-size: 0.6rem;
  font-weight: 450;
  color: white;
  letter-spacing: 0.5px;
  /* outline: #AED2FF; */
  cursor: pointer;
}


.formFooterWrapper {
  grid-area: formFooterWrapper;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.formFooterWrapper p {
  font-family: "Poppins", sans-serif;
  font-size: 0.6rem;
  font-weight: var(--bolder-font);
  color: var(--primary-font-color);
}

.spanText {
  font-family: "Poppins", sans-serif;
  font-size: 0.6rem;
  font-weight: var(--bolder-font);
  color: var(--ui-scheme-color);
  margin-left: 2px;
  text-decoration: none;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .activateAccountForm {
    position: relative;
    width: 83%;
    aspect-ratio: 3/2.5;
    border-radius: 2rem;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 30% auto 25% 20%;
    grid-template-areas:
      "formHeaderWrapper formHeaderWrapper"
      "formMessageWrapper formMessageWrapper "
      "formActivateBtnWrapper formActivateBtnWrapper"
      "formFooterWrapper formFooterWrapper";
    justify-content: center;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* border: 1px solid springgreen; */
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
}