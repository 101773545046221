@import "../../variable.modules.css";

.documentComponentWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 200px;
    background-color: var(--box-bg-primary);
    border-radius: 15px;
    margin-left: 20px;
    margin-top: 20px;
    box-shadow: var(--list-wrapper-box-shadow);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.3rem;
}

.documentComponentWrapperFocus {
    border: 3px solid var(--ui-scheme-color);
    box-sizing: border-box;
}

.documenComponentContainer {
    position: relative;
    width: 95%;
    height: 95%;
    display: grid;
    grid-template-rows: 85% 15%;
    grid-template-columns: 100%;
    grid-template-areas:
        "imageContainer"
        "footerContainer";

}

.imageContainer {
    position: relative;
    grid-area: imageContainer;
    width: 100%;
    height: 90%;
    /* border: 1px solid var(--box-bg-tertiary-drk); */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    overflow: hidden;
    /* border: 1px solid orange; */
}



.footerContainer {
    position: relative;
    grid-area: footerContainer;
    width: 100%;
    height: 100%;
    /* border: 1px solid orange; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.titleDiv {
    position: relative;
    width: 80%;
    height: 70%;
    /* border: 1px solid white; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    overflow: hidden;
    border-radius: 0.3rem;
}

.favBtnContainer {
    position: relative;
    width: 20%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid white; */
    /* background-color: blueviolet; */
}

.favBtnDiv {
    position: relative;
    height: 100%;
    aspect-ratio: 1/1;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    border-radius: 50%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

    .documentComponentWrapper {
        position: relative;
        width: 100%;
        height: 200px;
        margin: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 19px;
    }

    .documenComponentContainer {
        position: relative;
        width: 92%;
        height: 92%;
        display: grid;
        grid-template-rows: 85% 15%;
        grid-template-columns: 100%;
        grid-template-areas:
            "imageContainer"
            "footerContainer";
        justify-content: center;
        align-items: center;
        /* border: 1px solid black; */
    }


    .footerContainer {
        position: relative;
        grid-area: footerContainer;
        width: 100%;
        height: 100%;
        /* border: 1px solid orange; */
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

}