@import "../../../variable.modules.css";

.fieldWrapper {
    position: relative;
    width: 78%;
    height: 50px;
    background-color: var(--box-bg-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.9rem;
    margin-top: 8px;
    box-shadow: var(--dashboard-activity-field-shadow);
    box-sizing: border-box;
}

.fieldContainer {
    position: relative;
    width: 90%;
    height: 100%;
    /* border: 1px solid orange; */
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    grid-template-rows: 100%;
    grid-template-areas:
        "fieldNameLabelWrapper valWrapper";
    box-sizing: border-box;
}


.fieldNameLabelWrapper {
    grid-area: fieldNameLabelWrapper;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    white-space: nowrap;
    /* border: 1px solid skyblue; */
}

.fieldNameLabelWrapper p {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: var(--bolder-font);
    color: var(--primary-font-color);
    margin: 0px;
    padding: 0px;
}

.valWrapper {
    grid-area: valWrapper;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0rem;
    overflow: hidden;
    /* border: 0.03rem solid palevioletred; */
}

.valWrapper p {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: var(--bolder-font);
    color: var(--ui-scheme-color);
    margin: 0px;
    padding: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fieldWrapper::before {
    position: absolute;
    left: 0px;
    content: "";
    background-color: var(--ui-scheme-color);
    width: 2.5px;
    height: 40%;
    border-radius: 10px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

    .fieldWrapper {
        border-radius: clamp(0.8rem, 0.40000000000000013rem + 1.9999999999999998vw, 1.0rem) !important;
    }

    .fieldNameLabelWrapper p {
        font-size: clamp(0.65rem, 0.2500000000000001rem + 1.9999999999999998vw, 0.85rem) !important;
    }

    .valWrapper p {
        font-size: clamp(0.65rem, 0.2500000000000001rem + 1.9999999999999998vw, 0.85rem) !important;
    }

}