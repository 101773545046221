.recentAddedList {
    position: relative;
    height: 90%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 0;
    list-style-type: none;
    box-sizing: border-box;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    transform: perspective(1px) translateZ(0);
    backface-visibility: hidden;
    scroll-behavior: smooth;
    overflow-y: scroll;
    gap: 0.3rem;
    padding-top: 0%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 1.2rem;
    /* border: 1px solid salmon; */
    margin-top: 5%;
}

.recentAddedList::-webkit-scrollbar {
    background-color: rgba(255, 255, 255, 0);
    height: 4px;
    width: 0.15rem;
}

.recentAddedList::-webkit-scrollbar-track {
    background-color: rgb(231, 228, 228);
    background-color: rgba(231, 228, 228, 0.029);
    width: 2px;
}

.recentAddedList::-webkit-scrollbar-thumb {
    background-color: var(--ui-scheme-color);
    border-radius: 4px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .recentAddedList {
        position: relative;
        width: 100%;
        height: 95%;
        min-height: 95%;
        display: flex;
        flex-wrap: unset;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow-y: scroll !important;
        box-sizing: border-box;
        scrollbar-width: none;
        /* border: 1px solid red; */
        gap: unset;
        margin: unset;
        list-style-type: unset;
        box-sizing: border-box;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: unset;
        padding-right: unset;
        /* scroll-snap-align: start; */

    }

}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {

    .recentAddedList {
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        position: relative;
        height: 95%;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        scroll-behavior: smooth;
        overflow-y: scroll;
        padding-top: unset;
        padding-left: unset;
        padding-bottom: unset;
        box-sizing: border-box;
        scrollbar-width: none;
        /* firefox hide scrollbar  */
        -ms-overflow-style: none;
        /* firefox hide scrollbar  */
        /* border: 1px solid rebeccapurple; */

        padding-left: 0px;
        padding-right: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 5%;
        margin-bottom: 0px;

        /* border: 1px solid skyblue; */
    }
}