@import "../../variable.modules.css";

.cardWrapper {
    /* display: flex; */
    display: none;
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--box-bg-primary);
    padding-left: 5%;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* border: 1px solid black; */
}

.cardWrapperClose {
    /* display: flex; */
    display: none;
    width: 0%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--box-bg-primary);
    padding-left: 0%;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    scroll-behavior: smooth;

}

.cardContainer {
    width: 70%;
    height: 90%;
    /* border: 1px solid red;

    display: grid;
    grid-template-columns: 10% 90%;
    grid-template-rows: 15% 20% 15% 10% 20% 20%;
    grid-template-rows: 10% 20% 15% 15% 20% 20%;

    grid-template-areas:
        "cardHeader cardHeader"
        "logoTitleWrapper logoTitleWrapper"
        "categoryWrapper categoryWrapper"
        "cardHolderWrapper cardHolderWrapper"
        "cardNumberWrapper cardNumberWrapper"
        "dateOfBirthWrapper expiryIssueWrapper";
    box-sizing: border-box;
    */
    display: grid;
    grid-template-columns: 25% minmax(0, 1fr);
    grid-template-rows: 10% 17% 14% 14% 22% 22%;
    grid-template-rows: 10% 18% 14% 14% 22% 22%;
    grid-template-rows: 10% 20% 15% 55%;
    grid-template-areas:
        "cardHeader cardHeader"
        "logoTitleWrapper logoTitleWrapper"
        "categoryWrapper categoryWrapper"
        "subcardWrapper subcardWrapper";
    padding-bottom: 0%;
    padding-top: 0%;
    box-sizing: border-box;
    /* border: 1px solid red; */
}

.cardHeader {
    grid-area: cardHeader;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid orange; */
}

.backBtnContainer {
    width: 50%;
    height: 100%;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0%;
    padding-top: 0%;
}

.backBtnDiv {
    /* width: 40%; */
    height: 55%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0%;
    padding-top: 0%;
    cursor: pointer;

}

.crudBtnContainer {
    width: 50%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.saveBtnDiv {
    width: auto;
    height: 22px;
    height: 25px;
    /* border: 1px solid black; */
    background-color: #58BF6F;
    /* background-color: white; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 5px;
    margin-right: 20px;
    /* transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    cursor: pointer;

}

.spinner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    width: 100%;
}

.saveBtnDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 11px;
    font-weight: 400;
    color: white;
}

.crudIcons {
    margin-right: 5px;
}

.logoTitleWrapper {
    grid-area: logoTitleWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* border: 1px solid blue; */
    padding-left: 0%;
}

.logoTitleContainer {
    width: 100%;
    height: 85%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0%;
}

.logoContainer {
    /* width: auto; */
    /* width: 30%; */
    height: 100%;
    aspect-ratio: 1/1;
    /* border: 1px solid orange; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* border: 1px solid green; */
    margin-right: 2%;
}

.logoDiv {
    /* width: 80%; */
    aspect-ratio: 1/1;
    height: 100%;
    border: 3px solid #a8ccf9b5;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: var(--logo-div-bg); */
    cursor: pointer;

}

.logoSelectedBg {
    background-color: var(--logo-div-bg);
}

.logoIcon {
    width: 100%;
    height: 100%;
}

.titleContainer {
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 5%;
    /* border: 1px solid red; */
}

.titleLabelDiv {
    width: 80%;
    height: 80%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.titleLabelDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 0.85rem;
    font-weight: var(--bolder-font);
    color: var(--secondary-font-color);
}

.titleInputDiv {
    width: 100%;
    height: 80%;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.titleInputDiv input {
    width: 90%;
    height: 100%;
    border-bottom: none;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    background: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 1.2rem;
    font-weight: var(--bold-font);
    color: var(--primary-font-color);
}

.categoryWrapper {
    grid-area: categoryWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid rgb(182, 69, 88); */
    padding-left: 0%;
}

.categoryContainer {
    width: 100%;
    height: 60%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--box-bg-tertiary);
    border-radius: 10px;

}

.categoryLabelDiv {
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5%;
}

.categoryLabelDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 1.2rem;
    font-weight: var(--bold-font);
    color: var(--primary-font-color);
}

.catergoryInputDiv {
    position: relative;
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* border: 1px solid black; */
}

.categoryInput {
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem;
    font-weight: var(--bold-font);
    color: var(--ui-scheme-color);
    background-color: var(--category-box-bg);
    border: none;
    outline: none !important;
    height: 75%;
    width: 80%;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.popUpBtnIconDiv {
    width: 15%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.popUpIcon {
    font-size: 1.1rem;
    color: var(--primary-font-color);
    cursor: pointer;

}

.categoryInput:focus {
    /* border: 1px solid #0475ff84; */
}

.inputPopUpDiv {
    top: 115%;
    position: absolute;
    width: 120px;
    right: 20%;
    height: auto;
    background-color: var(--box-bg-tertiary-drk);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 10%;
    border-radius: 5px;
    box-shadow: var(--applogo-box-shadow);
    z-index: 100;
}

.inputPopUpText {
    font-family: "Poppins", sans-serif;
    font-size: 0.75rem;
    font-weight: var(--bold-font);
    color: var(--primary-font-color);
    margin: 5px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid var(--box-bg-tertiary);
    padding-left: 8%;
    padding-right: 5%;
    width: 90%;
    letter-spacing: 0.3px;
}

.inputPopUpText:hover {
    background-color: #a8ccf9b5;
    border-radius: 5px;
    padding-left: 5%;
    padding-right: 5%;
    width: 80%;
}

.subCardWrapper {
    grid-area: subcardWrapper;
    display: grid;
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
}



.focusFieldStyle {
    border: 2px solid var(--ui-scheme-color);
    border-radius: 15px !important;
}




@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

    .cardWrapper {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        padding-left: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-top: 0%;
        margin-bottom: 0.2%;
        transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    .cardWrapperClose {
        display: flex;
        width: 100%;
        height: 0%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 0%;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        overflow: hidden;
        padding: 0px;
        margin: 0px;
        transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    .cardContainer {
        /* border: 1px solid black; */
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 25% minmax(0, 1fr);
        grid-template-rows: 10% 17% 14% 14% 22% 22%;
        grid-template-rows: 10% 18% 14% 14% 22% 22%;
        grid-template-rows: 10% 20% 15% 55%;
        grid-template-areas:
            "cardHeader cardHeader"
            "logoTitleWrapper logoTitleWrapper"
            "categoryWrapper categoryWrapper"
            "subcardWrapper subcardWrapper";
        padding-bottom: 0%;
        padding-top: 0%;
        box-sizing: border-box;

    }

    .cardHeader {
        grid-area: cardHeader;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid orange; */
    }

    .backBtnContainer {
        width: 50%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 5%;
        padding-top: 0%;
    }

    .backBtnDiv {
        width: 50%;
        height: 52%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }



    .crudBtnContainer {
        width: 50%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }


    .logoTitleWrapper {
        grid-area: logoTitleWrapper;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid blue; */
        padding-left: 0%;
    }

    .logoTitleContainer {
        width: 90%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .logoContainer {
        /* width: auto; */
        /* width: 30%; */
        height: 100%;
        aspect-ratio: 1/1;
        /* border: 1px solid orange; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
        /* border: 1px solid green; */
        margin-right: 2%;
    }


    .logoDiv {
        /* width: 80%; */
        aspect-ratio: 1/1;
        height: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px solid #a8ccf9b5;
        border-radius: clamp(0.9rem, 0.09999999999999998rem + 4.0vw, 1.3rem) !important;
    }

    .logoIcon {
        width: 100%;
        height: 100%;
    }

    .titleContainer {
        width: 100%;
        height: 100%;
        /* border: 1px solid red; */
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding-left: 5%;
    }

    .titleLabelDiv {
        width: 80%;
        height: 80%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    }

    .titleLabelDiv p {
        font-size: 1rem;
        font-size: clamp(0.7rem, 0.09999999999999987rem + 3.0000000000000004vw, 1.0rem) !important;
    }

    .titleInputDiv {
        width: 90%;
        height: 100%;
        /* border: 1px solid blue; */
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .titleInputDiv p {
        font-family: "Poppins", sans-serif;
        font-size: 1.5rem;
        font-size: clamp(1.1rem, 0.30000000000000027rem + 3.9999999999999996vw, 1.5rem) !important;
    }

    .categoryWrapper {
        grid-area: categoryWrapper;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid rgb(182, 69, 88); */
        padding-left: 0%;

    }

    .categoryContainer {
        width: 90%;
        height: 60%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border-radius: clamp(0.5rem, 0.0rem + 2.5vw, 0.75rem) !important;

    }

    .categoryLabelDiv {
        width: 40%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 5%;
    }

    .categoryLabelDiv p {
        font-size: 1.2rem;
        margin: 0px;
        padding: 0px;
        font-size: clamp(1.0rem, 0.0rem + 5.0vw, 1.5rem) !important;
    }

    .catergoryInputDiv {
        position: relative;
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        /* border: 1px solid black; */
    }

    .categoryInput {
        font-size: 1.1rem;
        outline: none !important;
        height: 80%;
        width: 85%;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: clamp(0.85rem, -0.15000000000000024rem + 5.000000000000001vw, 1.35rem) !important;
    }

    .popUpBtnIconDiv {
        width: 15%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .popUpIcon {
        font-size: 20px;
    }

    .categoryInput:focus {
        /* border: 1px solid #0475ff84; */
    }

    .inputPopUpDiv {
        top: 110%;
        right: 20%;
        position: absolute;
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 5%;
        padding-bottom: 5%;
        padding-left: 10%;
        padding-right: 30%;
        border-radius: 5px;
    }

    .inputPopUpText {
        margin: 20% 0px 0px 0px;
        font-size: clamp(0.75rem, -0.1499999999999999rem + 4.5vw, 1.2rem) !important;
    }

    .inputPopUpText:hover {
        width: 80%;
        background-color: unset;
    }

    .subCardWrapper {
        grid-area: subcardWrapper;
        display: grid;
        width: 100%;
        height: 100%;
        /* border: 1px solid red; */
    }

}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {

    .cardWrapper {
        display: none;
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: var(--box-bg-primary);
        padding-left: 5%;
        webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        overflow: hidden;
        /* border: 1px solid red; */
        padding-top: 5%;
    }

    .cardWrapperClose {
        display: none;
        width: 0%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: var(--box-bg-primary);
        padding-left: 0%;
        webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        overflow: hidden;
        transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
        scroll-behavior: smooth;
    }

    .cardContainer {
        width: 93%;
        height: 50%;
        display: grid;
        grid-template-columns: 25% minmax(0, 1fr);
        grid-template-rows: 10% 17% 14% 14% 22% 22%;
        grid-template-rows: 10% 18% 14% 14% 22% 22%;
        grid-template-rows: 10% 20% 15% 55%;
        grid-template-areas:
            "cardHeader cardHeader"
            "logoTitleWrapper logoTitleWrapper"
            "categoryWrapper categoryWrapper"
            "subcardWrapper subcardWrapper";
        padding-bottom: 0%;
        padding-top: 0%;
        box-sizing: border-box;
        /* border: 1px solid blue; */

    }




}