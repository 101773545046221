.logoListWrapper {
    grid-area: logoListWrapper;
    width: 100%;
    height: 95%;
    /* border: 1px solid red; */
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    grid-template-columns: 1fr 1fr 1fr;
    /* gap: 20px; */
    gap: 5%;
    gap: 8%;
    list-style-type: none;
    padding-top: 2%;
    padding-bottom: 20%;
    padding-left: 8%;
    padding-right: 8%;
    overflow-y: scroll !important;
    margin-top: 5%;
    /* display: none; */
}


.logoListWrapper::-webkit-scrollbar {
    background-color: rgba(176, 32, 32, 0);
    height: 4px;
    width: 3px;
}

.logoListWrapper::-webkit-scrollbar-track {
    background-color: rgb(231, 228, 228);
    background-color: rgba(231, 228, 228, 0.062);
    width: 2px;
}

.logoListWrapper::-webkit-scrollbar-thumb {
    background-color: var(--ui-scheme-color);
    border-radius: 10px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {}