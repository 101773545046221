@import "../../variable.modules.css";


.subCardWrapper {
    position: relative;
    /* border: 1px solid blue; */
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 33% 33% 33%;
    grid-template-areas:
        "cardHolderWrapper cardHolderWrapper"
        "cardNumberWrapper cardNumberWrapper"
        "expiryDateWrapper cvvNumberWrapper";
    padding-bottom: 0%;
    padding-top: 0%;
    box-sizing: border-box;
    justify-items: center;
}


/* _____________________________________________ */

.cardHolderWrapper {
    grid-area: cardHolderWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid orange; */
}

/* .cardHolderContainer {
    height: 70%;
    display: grid;
    align-items: center;
    grid-template-columns: 20% 80%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
        "cardHolderIconDiv cardHolderLabelTextDiv"
        "cardHolderIconDiv cardHolderInputDiv";
      background-color: var(--box-bg-tertiary);

    border-radius: 10px;
} */

.cardHolderContainer {
    height: 70%;
    /* border: 1px solid black; */
    display: grid;
    align-items: center;
    grid-template-columns: 20% 80%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
        "cardHolderIconDiv cardHolderLabelTextDiv"
        "cardHolderIconDiv cardHolderInputDiv";
    background-color: var(--box-bg-tertiary);
    border-radius: 10px;
}

.cardHolderIconDiv {
    grid-area: cardHolderIconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardHolderIcon {
    font-size: 30px;
    color: var(--field-icon-color);
}

.cardHolderLabelTextDiv {
    grid-area: cardHolderLabelTextDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-items: center;
    align-items: center;
}

.cardHolderLabelTextDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;

    font-weight: var(--bolder-font);
    color: var(--secondary-font-color);
}

.cardHolderInputDiv {
    grid-area: cardHolderInputDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
}

.cardHolderInputActive {
    width: 95%;
    height: auto;
    border-bottom: none;
    border-top: none;
    border-left: none;
    border-right: none;
    /* border: 1px solid #7E8DA4; */
    outline: none;
    background: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bold-font);
    color: var(--primary-font-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cardHolderInputNotActive {
    width: 95%;
    height: auto;
    border: none;
    /* border: 1px solid #7E8DA4; */
    outline: none;
    background: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bold-font);
    color: var(--primary-font-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/* .cardHolderInputDiv input {
    width: 80%;
    height: 100%;
    border-bottom:none;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    background: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight:var(--bold-font);
    color: black;
} */

/* _____________________________________________________ */

/* _____________________________________________ */
.cardNumberWrapper {
    grid-area: cardNumberWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid green; */
}

.cardNumberContainer {
    height: 70%;
    /* border: 1px solid black; */
    display: grid;
    align-items: center;

    grid-template-columns: 20% 60% 20%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
        "cardNumberIconDiv cardNumberLabelTextDiv cardVenderIconContainer  "
        "cardNumberIconDiv cardNumberInputDiv cardVenderIconContainer";
    background-color: var(--box-bg-tertiary);

    border-radius: 10px;
}


.cardNumberIconDiv {
    grid-area: cardNumberIconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardNumIcon {
    font-size: 25px;
    color: var(--field-icon-color);
}

.cardNumberLabelTextDiv {
    grid-area: cardNumberLabelTextDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-items: center;
    align-items: center;
}

.cardNumberLabelTextDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--secondary-font-color);
}

.cardNumberInputDiv {
    grid-area: cardNumberInputDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
}

.cardVenderIconContainer {
    grid-area: cardVenderIconContainer;
    width: 100%;
    height: 100%;
    /* border: 1px solid green; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cardVenderIconDiv {
    aspect-ratio: 1/1;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

.cardVenderLogo {
    /* height: 70%; */
    width: 80%;
    /* border: 1px solid black; */
}




/* _____________________________________________ */

.expiryDateWrapper {
    grid-area: expiryDateWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: flex-start;
}

.expiryDateContainer {
    height: 70%;
    width: 90%;
    display: grid;
    align-items: center;
    grid-template-columns: 30% 70%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
        "expiryIconDiv expiryLabelTextDiv"
        "expiryIconDiv expiryInputDiv";
    background-color: var(--box-bg-tertiary);

    border-radius: 10px;
}

.expiryIconDiv {
    grid-area: expiryIconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.expiryDateIcon {
    font-size: 25px;
    color: var(--field-icon-color);
}


.expiryLabelTextDiv {
    grid-area: expiryLabelTextDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.expiryLabelTextDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--secondary-font-color);
}

.expiryInputDiv {
    grid-area: expiryInputDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}



/* _____________________________________________ */

.cvvNumberWrapper {
    grid-area: cvvNumberWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: flex-end;
}

.cvvNumberContainer {
    height: 70%;
    width: 90%;
    /* border: 1px solid black; */
    display: grid;
    align-items: center;
    grid-template-columns: 30% 70%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
        "cvvNumberIconDiv cvvNumberLabelTextDiv"
        "cvvNumberIconDiv cvvNumberInputDiv";
    background-color: var(--box-bg-tertiary);

    border-radius: 10px;
}

.cvvNumberIconDiv {
    grid-area: cvvNumberIconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.cvvIcon {
    font-size: 22px;
    color: var(--field-icon-color);
}

.cvvNumberLabelTextDiv {
    grid-area: cvvNumberLabelTextDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cvvNumberLabelTextDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--secondary-font-color);
}

.cvvNumberInputDiv {
    grid-area: cvvNumberInputDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}



.inputActive {
    width: 80%;
    height: auto;
    border-bottom: none;
    border-top: none;
    border-left: none;
    border-right: none;
    /* border: 1px solid #7E8DA4; */
    outline: none;
    background: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bold-font);
    color: var(--primary-font-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.inputNotActive {
    width: 80%;
    height: auto;
    border: none;
    /* border: 1px solid #7E8DA4; */
    outline: none;
    background: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bold-font);
    color: var(--primary-font-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.focusFieldStyle {
    border: 2px solid var(--ui-scheme-color);
}



@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {


    .subCardWrapper {
        position: relative;
        /* border: 1px solid blue; */
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 33% 33% 33%;
        grid-template-areas:
            "cardHolderWrapper cardHolderWrapper"
            "cardNumberWrapper cardNumberWrapper"
            "expiryDateWrapper cvvNumberWrapper";
        padding-bottom: 0%;
        padding-top: 0%;
        box-sizing: border-box;
        justify-items: center;
    }

    /* _____________________________________________ */

    .cardHolderWrapper {
        grid-area: cardHolderWrapper;
        width: 90%;
        height: 100%;
        /* border: 1px solid orange; */
    }

    .cardHolderContainer {
        height: 70%;
        /* border: 1px solid white; */
        display: grid;
        align-items: center;
        grid-template-columns: 20% 80%;
        grid-template-rows: 50% 50%;
        grid-template-areas:
            "cardHolderIconDiv cardHolderLabelTextDiv"
            "cardHolderIconDiv cardHolderInputDiv";
        border-radius: 10px;
        border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;
    }



    .cardHolderIconDiv {
        grid-area: cardHolderIconDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cardHolderIcon {
        /* font-size: 30px; */
        font-size: 1.6rem;
        font-size: 2.4rem;
        /* font-size: clamp(1.3rem, 0.10000000000000031rem + 5.999999999999998vw, 1.9rem) !important; */
        font-size: clamp(1.6rem, 4.440892098500626e-16rem + 7.999999999999999vw, 2.4rem) !important;
    }

    .cardHolderLabelTextDiv {
        grid-area: cardHolderLabelTextDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-items: center;
        align-items: center;
    }

    .cardHolderLabelTextDiv p {
        font-size: 0.9rem;
        font-weight: 450;
        font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;

    }

    .cardHolderInputDiv {
        grid-area: cardHolderInputDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
    }



    /* _____________________________________________________ */


    /* _____________________________________________ */
    .cardNumberWrapper {
        grid-area: cardNumberWrapper;
        width: 90%;
        height: 100%;
        /* border: 1px solid green; */
    }

    .cardNumberContainer {
        height: 70%;
        /* border: 1px solid black; */
        display: grid;
        align-items: center;
        grid-template-columns: 20% 60% 20%;
        grid-template-rows: 50% 50%;
        grid-template-areas:
            "cardNumberIconDiv cardNumberLabelTextDiv cardVenderIconContainer  "
            "cardNumberIconDiv cardNumberInputDiv cardVenderIconContainer";
        border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;
    }


    .cardNumberIconDiv {
        grid-area: cardNumberIconDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cardNumIcon {
        /* font-size: 25px; */
        font-size: 1.8rem;
        font-size: clamp(1.3rem, 0.10000000000000031rem + 5.999999999999998vw, 1.9rem) !important;


    }

    .cardNumberLabelTextDiv {
        grid-area: cardNumberLabelTextDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-items: center;
        align-items: center;
    }

    .cardNumberLabelTextDiv p {
        font-size: 0.9rem;
        font-weight: 450;
        font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;

    }

    .cardNumberInputDiv {
        grid-area: cardNumberInputDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
    }

    .cardVenderIconContainer {
        grid-area: cardVenderIconContainer;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .cardVenderIconDiv {
        aspect-ratio: 1/1;
        width: 55%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid red; */
    }

    .cardVenderLogo {
        /* height: 70%; */
        width: 100%;
        /* border: 1px solid green; */
    }

    /* _____________________________________________ */

    .expiryDateWrapper {
        grid-area: expiryDateWrapper;
        width: 100%;
        height: 100%;
        /* border: 1px solid blue; */
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 10%;
    }

    .expiryDateContainer {
        height: 70%;
        width: 90%;
        /* border: 1px solid black; */
        display: grid;
        align-items: center;
        grid-template-columns: 30% 70%;
        grid-template-rows: 50% 50%;
        grid-template-areas:
            "expiryIconDiv expiryLabelTextDiv"
            "expiryIconDiv expiryInputDiv";
        border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;

    }

    .expiryIconDiv {
        grid-area: expiryIconDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .expiryDateIcon {
        /* font-size: 25px; */
        font-size: 1.3rem;
        /* font-size: 1.9rem; */
        font-size: clamp(1.3rem, 0.10000000000000031rem + 5.999999999999998vw, 1.9rem) !important;

    }


    .expiryLabelTextDiv {
        grid-area: expiryLabelTextDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .expiryLabelTextDiv p {
        font-size: 0.9rem;
        font-weight: 450;
        font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;
    }

    .expiryInputDiv {
        grid-area: expiryInputDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid white; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }




    /* _____________________________________________ */

    .cvvNumberWrapper {
        grid-area: cvvNumberWrapper;
        width: 100%;
        height: 100%;
        /* border: 1px solid red; */
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .cvvNumberContainer {
        height: 70%;
        width: 90%;
        /* border: 1px solid black; */
        display: grid;
        align-items: center;
        grid-template-columns: 30% 70%;
        grid-template-rows: 50% 50%;
        grid-template-areas:
            "cvvNumberIconDiv cvvNumberLabelTextDiv"
            "cvvNumberIconDiv cvvNumberInputDiv";
        border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;

    }

    .cvvNumberIconDiv {
        grid-area: cvvNumberIconDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cvvIcon {
        /* font-size: 22px; */
        /* font-size: 1.3rem; */
        /* font-size: 1.9rem; */
        font-size: clamp(1.3rem, 0.10000000000000031rem + 5.999999999999998vw, 1.9rem) !important;

    }

    .cvvNumberLabelTextDiv {
        grid-area: cvvNumberLabelTextDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .cvvNumberLabelTextDiv p {
        font-size: 0.9rem;
        font-weight: 450;
        font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;

    }

    .cvvNumberInputDiv {
        grid-area: cvvNumberInputDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .cardHolderInputActive {
        font-size: 0.9rem;
        font-weight: 450;
        font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;
    }

    .cardHolderInputNotActive {
        font-size: 0.9rem;
        font-weight: 450;
        font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;

    }

    .inputActive {
        font-size: 0.9rem;
        font-weight: var(--bold-font);
        font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;
    }

    .inputNotActive {
        font-size: 0.9rem;
        font-weight: var(--bold-font);
        font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;

    }

    .inputActive::placeholder {
        font-weight: var(--bold-font);
        /* font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important; */
        /* color: var(--secondary-font-color); */
        text-align: start;
        font-size: clamp(0.6rem, -1.1102230246251565e-16rem + 3.0000000000000004vw, 0.9rem) !important;
    }
}