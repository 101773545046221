@import "../../variable.modules.css";

.cardWrapper {
    /* border: 1px solid blue; */
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 33% 33% 33%;
    grid-template-areas:
        "cardHolderWrapper cardHolderWrapper"
        "cardNumberWrapper cardNumberWrapper"
        "dobDateWrapper expiryDateWrapper";
    padding-bottom: 0%;
    padding-top: 0%;
    box-sizing: border-box;
    justify-items: center;
}

/* _____________________________________________ */

.cardHolderWrapper {
    grid-area: cardHolderWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid orange; */
}

.cardHolderContainer {
    height: 70%;
    /* border: 1px solid black; */
    display: grid;
    align-items: center;
    grid-template-columns: 20% 80%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
        "cardHolderIconDiv cardHolderLabelTextDiv"
        "cardHolderIconDiv cardHolderInputDiv";
    background-color: var(--box-bg-tertiary);
    border-radius: 10px;
}

.cardHolderIconDiv {
    grid-area: cardHolderIconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardHolderIcon {
    font-size: 30px;
    color: var(--field-icon-color);
}


.cardHolderLabelTextDiv {
    grid-area: cardHolderLabelTextDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-items: center;
    align-items: center;
}

.cardHolderLabelTextDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--secondary-font-color);
}

.cardHolderInputDiv {
    grid-area: cardHolderInputDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
}

.cardHolderInputActive {
    width: 95%;
    height: auto;
    border-bottom: none;
    border-top: none;
    border-left: none;
    border-right: none;
    /* border: 1px solid #7E8DA4; */
    outline: none;
    background: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bold-font);
    color: var(--primary-font-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

input:focus,
input.form-control:focus {

    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.cardHolderInputNotActive {
    width: 95%;
    height: auto;
    border: none;
    /* border: 1px solid #7E8DA4; */
    outline: none;
    background: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bold-font);
    color: var(--primary-font-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/* _____________________________________________ */
.cardNumberWrapper {
    grid-area: cardNumberWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid green; */
}

.cardNumberContainer {
    height: 70%;
    /* border: 1px solid black; */
    display: grid;
    align-items: center;
    grid-template-columns: 20% 80%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
        "cardNumberIconDiv cardNumberLabelTextDiv"
        "cardNumberIconDiv cardNumberInputDiv";
    background-color: var(--box-bg-tertiary);
    border-radius: 10px;
}


.cardNumberIconDiv {
    grid-area: cardNumberIconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardNumIcon {
    font-size: 25px;
    color: var(--field-icon-color);
}

.cardNumberLabelTextDiv {
    grid-area: cardNumberLabelTextDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-items: center;
    align-items: center;
}

.cardNumberLabelTextDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--secondary-font-color);
}

.cardNumberInputDiv {
    grid-area: cardNumberInputDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
}




/* _____________________________________________ */

.dobDateWrapper {
    grid-area: dobDateWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    /* border: 1px solid blue; */
}

.dobDateContainer {
    height: 70%;
    width: 90%;
    /* border: 1px solid black; */
    display: grid;
    align-items: center;
    grid-template-columns: 30% 70%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
        "dobIconDiv dobLabelTextDiv"
        "dobIconDiv dobDateInputDiv";
    background-color: var(--box-bg-tertiary);
    border-radius: 10px;
}

.dobIconDiv {
    grid-area: dobIconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.doBIcon {
    font-size: 22px;
    color: var(--field-icon-color);
}

.dobLabelTextDiv {
    grid-area: dobLabelTextDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dobLabelTextDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--secondary-font-color);
}

.dobDateInputDiv {
    grid-area: dobDateInputDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}



/* _____________________________________________ */

.expiryDateWrapper {
    grid-area: expiryDateWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    /* border: 1px solid red; */
}

.expiryDateContainer {
    height: 70%;
    width: 90%;
    /* border: 1px solid black; */
    display: grid;
    align-items: center;
    grid-template-columns: 30% 70%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
        "expiryIconDiv expiryLabelTextDiv"
        "expiryIconDiv expiryDateInputDiv";
    background-color: var(--box-bg-tertiary);
    border-radius: 10px;
}

.expiryIconDiv {
    grid-area: expiryIconDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.expiryIcon {
    font-size: 25px;
    color: var(--field-icon-color);
}

.expiryLabelTextDiv {
    grid-area: expiryLabelTextDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.expiryLabelTextDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bolder-font);
    color: var(--secondary-font-color);
}

.expiryDateInputDiv {
    grid-area: expiryDateInputDiv;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}



.inputActive {
    width: 80%;
    height: auto;
    border-bottom: none;
    border-top: none;
    border-left: none;
    border-right: none;
    /* border: 1px solid #7E8DA4; */
    outline: none;
    background: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bold-font);
    color: var(--primary-font-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.inputNotActive {
    width: 80%;
    height: auto;
    border: none;
    /* border: 1px solid #7E8DA4; */
    outline: none;
    background: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bold-font);
    color: var(--primary-font-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.focusFieldStyle {
    border: 2px solid var(--ui-scheme-color);

}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {


    .cardWrapper {
        /* border: 1px solid blue; */
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 33% 33% 33%;
        grid-template-areas:
            "cardHolderWrapper cardHolderWrapper"
            "cardNumberWrapper cardNumberWrapper"
            "dobDateWrapper expiryDateWrapper";
        padding-bottom: 0%;
        padding-top: 0%;
        box-sizing: border-box;
        justify-items: center;
    }

    /* _____________________________________________ */

    .cardHolderWrapper {
        grid-area: cardHolderWrapper;
        width: 90%;
        height: 100%;
        /* border: 1px solid orange; */
    }

    .cardHolderContainer {
        height: 70%;
        /* border: 1px solid black; */
        display: grid;
        align-items: center;
        grid-template-columns: 20% 80%;
        grid-template-rows: 50% 50%;
        grid-template-areas:
            "cardHolderIconDiv cardHolderLabelTextDiv"
            "cardHolderIconDiv cardHolderInputDiv";
        background-color: var(--box-bg-tertiary);
        border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;

    }

    .cardHolderIconDiv {
        grid-area: cardHolderIconDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cardHolderIcon {
        /* font-size: 30px; */
        font-size: 2rem;
        font-size: clamp(1.6rem, 4.440892098500626e-16rem + 7.999999999999999vw, 2.4rem) !important;
    }


    .cardHolderLabelTextDiv {
        grid-area: cardHolderLabelTextDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-items: center;
        align-items: center;
    }



    .cardHolderInputDiv {
        grid-area: cardHolderInputDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
    }


    /* _____________________________________________ */
    .cardNumberWrapper {
        grid-area: cardNumberWrapper;
        width: 90%;
        height: 100%;
        /* border: 1px solid green; */
    }

    .cardNumberContainer {
        height: 70%;
        /* border: 1px solid black; */
        display: grid;
        align-items: center;
        grid-template-columns: 20% 80%;
        grid-template-rows: 50% 50%;
        grid-template-areas:
            "cardNumberIconDiv cardNumberLabelTextDiv"
            "cardNumberIconDiv cardNumberInputDiv";
        background-color: var(--box-bg-tertiary);
        border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;

    }


    .cardNumberIconDiv {
        grid-area: cardNumberIconDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cardNumIcon {
        /* font-size: 25px; */
        font-size: 1.8rem;
        font-size: clamp(1.3rem, 0.10000000000000031rem + 5.999999999999998vw, 1.9rem) !important;
    }

    .cardNumberLabelTextDiv {
        grid-area: cardNumberLabelTextDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-items: center;
        align-items: center;
    }

    .cardNumberInputDiv {
        grid-area: cardNumberInputDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
    }



    /* _____________________________________________ */

    .dobDateWrapper {
        grid-area: dobDateWrapper;
        width: 100%;
        height: 100%;
        /* border: 1px solid blue; */
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 10%;
    }

    .dobDateContainer {
        height: 70%;
        width: 90%;
        /* border: 1px solid black; */
        display: grid;
        align-items: center;
        grid-template-columns: 30% 70%;
        grid-template-rows: 50% 50%;
        grid-template-areas:
            "dobIconDiv dobLabelTextDiv"
            "dobIconDiv dobDateInputDiv";
        background-color: var(--box-bg-tertiary);
        border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;

    }

    .dobIconDiv {
        grid-area: dobIconDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .doBIcon {
        /* font-size: 22px; */
        font-size: 1.55rem;
        font-size: clamp(1.1rem, 0.10000000000000009rem + 5.0vw, 1.6rem) !important;
    }

    .dobLabelTextDiv {
        grid-area: dobLabelTextDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .dobDateInputDiv {
        grid-area: dobDateInputDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }



    /* _____________________________________________ */

    .expiryDateWrapper {
        grid-area: expiryDateWrapper;
        width: 100%;
        height: 100%;
        /* border: 1px solid red; */
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .expiryDateContainer {
        height: 70%;
        width: 90%;
        /* border: 1px solid black; */
        display: grid;
        align-items: center;
        grid-template-columns: 30% 70%;
        grid-template-rows: 50% 50%;
        grid-template-areas:
            "expiryIconDiv expiryLabelTextDiv"
            "expiryIconDiv expiryDateInputDiv";
        background-color: var(--box-bg-tertiary);
        border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;

    }

    .expiryIconDiv {
        grid-area: expiryIconDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .expiryIcon {
        /* font-size: 35px; */
        font-size: 1.8rem;
        font-size: clamp(1.3rem, 0.10000000000000031rem + 5.999999999999998vw, 1.9rem) !important;
    }

    .expiryLabelTextDiv {
        grid-area: expiryLabelTextDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }



    .expiryDateInputDiv {
        grid-area: expiryDateInputDiv;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }


    /* FONT STYLES */
    .cardHolderLabelTextDiv p {
        font-size: 0.9rem;
        font-weight: 450;
        font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;

    }

    .cardHolderInputActive {
        font-size: 0.9rem;
        font-weight: 450;
        font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;

    }

    .cardHolderInputNotActive {
        font-size: 0.9rem;
        font-weight: 450;
        font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;


    }

    .cardNumberLabelTextDiv p {
        font-size: 0.9rem;
        font-weight: 450;
        font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;

    }

    .dobLabelTextDiv p {
        font-size: 0.9rem;
        font-weight: 450;
        font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;
    }

    .expiryLabelTextDiv p {
        font-size: 0.9rem;
        font-weight: 450;
        font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;

    }

    .inputActive {
        font-size: 0.9rem;
        font-weight: 450;
        font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;

    }

    .inputNotActive {
        font-size: 0.9rem;
        font-weight: 450;
        font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;

    }

    .inputActive::placeholder {
        font-weight: var(--bold-font);
        font-size: clamp(0.75rem, 0.04999999999999982rem + 3.500000000000001vw, 1.1rem) !important;
        color: var(--secondary-font-color);
        text-align: start;
        padding-left: 0.1rem;
        font-size: clamp(0.6rem, -1.1102230246251565e-16rem + 3.0000000000000004vw, 0.9rem) !important;
    }
}