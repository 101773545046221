.cardWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 280px;
    height: 75px;
    background-color: var(--box-bg-primary);
    border-radius: 15px;
    scroll-behavior: smooth;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    margin: 15px 15px 15px 15px;
    margin: 2% 2% 2% 2%;
    box-shadow: var(--list-wrapper-box-shadow);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 95%;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
}

.cardContainer {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 80px minmax(0, 1fr) minmax(0, 0.2fr);
    grid-template-rows: 25% 75%;
    grid-template-areas:
        "logoWrapper dateWrapper dateWrapper"
        "logoWrapper titleWrapper titleWrapper";
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.logoWrapper {
    grid-area: logoWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid lawngreen; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoDiv {
    width: 80%;
    height: 80%;
    border-radius: 8px;
    background-color: var(--logo-div-bg);
    display: flex;
    justify-content: center;
    align-items: center;
}

.docIcon {
    width: 80%;
    aspect-ratio: 1/1;
}

.dateWrapper {
    position: relative;
    grid-area: dateWrapper;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    /* border: 1px solid lightgreen; */
    padding-right: 0.15rem;
    /* padding-right: 0%; */
    padding-left: 5%;
    padding-top: 0.15rem;
    overflow: hidden;
}

.dateDiv {
    position: relative;
    width: auto;
    height: auto;
    /* border: 1px solid skyblue; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 4px 14px 4px 4px;
    /* background-color: #c7bafd8e; */
    /* background-color: #00299a75; */
    background-color: var(--recent-date-box-bg);
    padding-left: 3%;
    padding-right: 5%;
}

.dateDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 0.7rem;
    font-weight: var(--bolder-font);
    white-space: nowrap;
    color: var(--listCount-font-color);
}

.titleWrapper {
    grid-area: titleWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid skyblue; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 5%;
}

.titleWrapper p {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: var(--bold-font);
    color: var(--primary-font-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .cardWrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        scroll-behavior: smooth;
        transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
        width: 83% !important;
        padding: 0;
        margin: 0.80rem auto 0.80rem auto;
        border-radius: clamp(0.8rem, -0.19999999999999996rem + 5.0vw, 1.3rem) !important;
        height: clamp(4.0rem, 0.0rem + 20.0vw, 6.0rem) !important;
        min-height: clamp(4.0rem, 0.0rem + 20.0vw, 6.0rem) !important;
        /* border: 1px solid white; */
    }

    .cardContainer {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 25% minmax(0, 1fr);
        grid-template-rows: minmax(0, 0.5fr) minmax(0, 1fr);
        grid-template-areas:
            "logoWrapper dateWrapper"
            "logoWrapper titleWrapper";
        transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
        /* border: 1px solid gray; */
    }



    .logoDiv {
        width: 85%;
        height: 85%;
        border-radius: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: clamp(0.65rem, -0.04999999999999993rem + 3.4999999999999996vw, 1.0rem) !important;

    }

    .titleWrapper p {
        font-size: clamp(0.78rem, 0.1399999999999999rem + 3.2000000000000006vw, 1.1rem) !important;
    }
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {

    .cardWrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 75px;
        min-height: 75px;
        border-radius: 15px;
        scroll-behavior: smooth;
        transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
        margin: 2% 2% 2% 2%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        margin: 0.80rem auto 0.80rem auto;
    }
}