.logoSearchWrapper {
    grid-area: logoSearchWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputContainer {
    width: 80%;
    height: 80%;
    /* border: 1px solid black; */
    /* background-color: #ebebeb; */
    background-color: var(--box-bg-tertiary);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputDiv {
    width: 85%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
}

.inputClearIconDiv {
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* border: 1px solid black; */
}

.searchInput {
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
    height: 80%;
    display: flex;
    text-align: start;
    padding-left: 20%;
    padding-right: 5%;
    box-sizing: border-box;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: var(--bolder-font);
    color: var(--primary-font-color);
}

.inputClearIcon {
    font-size: 15px;
    color: var(--primary-font-color);
    cursor: pointer;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .searchInput {
        font-size: 1.1rem;
        font-weight: var(--bolder-font);
        color: var(--primary-font-color);
    }
}