:root {
  /* -> All ui fonts divide in two types */
  --ui-scheme-color: #002a9a;
  /* --ui-scheme-color: #9F7EE9; */

  --primary-font-color: black;
  --secondary-font-color: #8b98a5;
  --tertiary-font-color: #837fa2;
  --tertiary-font-color-li: #a8a4c5;

  --listCount-font-color: white;

  /*-> All ui div-bg divided into three types */
  --box-bg-primary: white;
  --box-bg-secondary: #f3f5f9;
  --box-bg-secondary-drk: #add2ff;
  --box-bg-tertiary: #e7effc;
  --box-bg-tertiary-drk: white;

  --logo-div-bg: #f3f5f9;
  --category-box-bg: #a6cbfb;

  --appLogo-bg-desktop: white;
  --appLogo-bg-mobile: white;

  --recent-date-box-bg: #00299a75;

  --date-picker-displaySelected-date-bg: #00299a2e;

  --shimmer-color: linear-gradient(90deg, #bdcbe15d 44.18%, #e7effc 129.07%);

  /* -> App ui bg */
  --app-main-bg: #151b3b;
  --app-main-bg: #2f3141;
  --app-main-bg: linear-gradient(
    180deg,
    #dfedf4 33.14%,
    #e8f0ec 51.89%,
    #f7f9f6 76.37%
  );

  /* 
background: linear-gradient(179.99deg, #AEC3CE 8.01%, rgba(177, 197, 208, 0.972857) 44.72%, #DDE3E4 141.46%); */
  /* -> form icons color */
  --field-icon-color: #002a9a;

  /* for btn */
  --submit-btn-color: #002a9a;

  /* -> font-weight */
  --bold-font: 400;
  --bolder-font: 500;
  --boldest-font: 600;

  /* -> box shadows */
  --list-wrapper-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --dashboard-countDiv-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --dashboard-activity-field-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --dashboard-activity-date-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --dashboard-activity-task-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --dashboard-activity-type-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --logoComp-logo-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  --logoComp-wrapper-box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  --applogo-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  --sidebar-link-text: #2f3e69;

}

[data-theme="dark"] {
  /* -> All ui fonts divide in two types */
  --ui-scheme-color: #c7bafd;
  /* --ui-scheme-color: #9F7EE9; */

  --primary-font-color: white;
  --secondary-font-color: #8b98a5;
  --tertiary-font-color: #837fa2;
  --tertiary-font-color-li: #a8a4c5;

  --listCount-font-color: black;

  /*-> All ui div-bg divided into three types */

  /* --box-bg-primary: #333A4D; */
  --box-bg-primary: #23262f;

  --box-bg-secondary: #414c68;
  --box-bg-secondary-drk: #4d6090;

  --box-bg-tertiary: #2f343e;
  --box-bg-tertiary-drk: #1c1f2c;

  --logo-div-bg: #d6d6d6;
  --category-box-bg: #1c1f2c;

  --appLogo-bg-desktop: #141416;
  --appLogo-bg-mobile: #23262f;

  --recent-date-box-bg: #c7bafd8e;
  --date-picker-displaySelected-date-bg: #c7bafd4d;

  --shimmer-color: linear-gradient(90deg, #60657571 44.18%, #30343d 129.07%);

  /* -> App ui bg */
  --app-main-bg: #151b3b;
  --app-main-bg: #2f3141;
  --app-main-bg: #1c1f2c;
  --app-main-bg: #141416;

  /* -> form icons color */
  --field-icon-color: #5294e2;
  /* --field-icon-color: #414c68; */

  /* for btn */
  --submit-btn-color: #5c8ed5;

  /* -> font-weight */
  --bold-font: 400;
  --bolder-font: 500;
  --boldest-font: 600;

  /* -> box shadows */
  --list-wrapper-box-shadow: none;
  --dashboard-countDiv-box-shadow: none;
  --dashboard-activity-field-shadow: none;
  --dashboard-activity-date-shadow: none;
  --dashboard-activity-task-shadow: none;
  --dashboard-activity-type-shadow: none;
  --logoComp-logo-box-shadow: none;
  --logoComp-wrapper-box-shadow: none;
  --applogo-box-shadow: none;

  --sidebar-link-text: white;


}
