@import "../../../variable.modules.css";

.activityComponentWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: auto;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: flex-end;
    box-sizing: border-box;
    margin-top: 2%;
    margin-left: 5%;
    padding-top: 0%;
    padding-bottom: 5%;
    border-bottom: 0.01rem solid #898E90;
    /* background-color: var(--box-bg-secondary); */
    scroll-snap-align: start;
}



.dateTimeTaskTypeWrapper {
    width: 100%;
    height: 70px;
    /* border: 1px solid white; */
    display: grid;
    grid-template-columns: 20% 30% 30%;
    grid-template-rows: 45% 55%;
    grid-template-areas:
        "dateWrapper timeWrapper timeWrapper"
        "dateWrapper typeWrapper taskWrapper";
}

.fieldWrapper {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding-right: 5.5%;
    margin-top: 2%;
}

.fieldWrapper::before {
    position: absolute;
    left: 7%;
    bottom: 0%;
    content: "";
    background-color: var(--box-bg-secondary-drk);
    background-color: #898E90;
    width: 0.01rem;
    height: 100%;
    z-index: 1;
}

/* ___________________________________________ */
.dateWrapper {
    position: relative;
    grid-area: dateWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid pink; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 2;
}

.dateWrapper::after {
    position: absolute;
    right: 15%;
    content: "";
    background-color: var(--ui-scheme-color);
    width: 0.1rem;
    height: 80%;
    border-radius: 1rem;
}

.dateContainer {
    width: 68%;
    height: 100%;
    background-color: var(--box-bg-primary);
    border-radius: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    box-shadow: var(--dashboard-activity-date-shadow);
}

.monthDiv {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 50%;
}

.monthDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: var(--bolder-font);
    color: var(--primary-font-color);
    margin: 0px;
    padding: 0px;
}


.dateDiv {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 50%;
    /* border: 1px solid black; */
}

.dateDiv p {
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-weight: var(--bolder-font);
    color: var(--ui-scheme-color);
    margin: 0px;
    padding: 0px;
}

/* ___________________________________________ */


.timeWrapper {
    grid-area: timeWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.timeContainer {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.timeContainer p {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: var(--bolder-font);
    color: var(--primary-font-color);
    margin: 0px;
    padding: 0px;
}

/* ___________________________________________ */

.typeWrapper {
    grid-area: typeWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid orange; */
    /* background-color: red; */
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.typeContainer {
    width: 80%;
    height: 70%;
    background-color: var(--box-bg-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    box-shadow: var(--dashboard-activity-type-shadow);
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.typeContainer p {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: var(--bolder-font);
    color: var(--primary-font-color);
    margin: 0px;
    padding: 0px;
}

/* ___________________________________________ */

.taskWrapper {
    grid-area: taskWrapper;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.taskContainer {
    width: 80%;
    height: 70%;
    background-color: var(--box-bg-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    box-shadow: var(--dashboard-activity-task-shadow);
    /* border: 2px solid #D64339; */
    box-sizing: border-box;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */

}

.taskContainerBorderAdded {
    /* border: 3px solid #58bf6e9b; */
    color: #58bf6e;
}

.taskContainerBorderDeleted {
    /* border: 3px solid #d6433976; */
    color: #d64339;
}

.taskContainerBorderEdit {
    /* border: 3px solid #9dc1fae6; */
    color: #668cf4;
}

.taskContainer p {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: var(--bolder-font);
    /* color: black; */
    margin: 0px;
    padding: 0px;
}

/* ___________________________________________ */

/* .innerBox {
    position: relative;
    width: 78%;
    height: 60px;
    background-color: red;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 15px;
    margin-top: 8px;
    margin-right: 5.5%;
}

.innerBox::before {
    position: absolute;
    left: 0px;
    content: "";
    background-color: #002A9A;
    width: 2.5px;
    height: 40%;
    border-radius: 10px;
} */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

    .dateContainer {
        position: relative;
        /* border-radius: 0.7rem; */
        /* border-radius: 1.1rem; */
        border-radius: clamp(0.7rem, -0.10000000000000031rem + 4.000000000000002vw, 1.1rem) !important;
    }

    .monthDiv p {
        /* font-size: 0.8rem; */
        /* font-size: 1rem; */
        font-size: clamp(0.8rem, 0.40000000000000013rem + 1.9999999999999998vw, 1.0rem) !important;
        margin: 0px;
        padding: 0px;
    }

    .dateDiv p {
        /* font-size: 22px; */
        /* font-size: 1.1rem; */
        /* font-size: 1.4rem; */
        font-size: clamp(1.1rem, 0.5000000000000004rem + 2.9999999999999982vw, 1.4rem) !important;
        margin: 0px;
        padding: 0px;
    }

    .timeContainer p {
        font-size: 12px;
    }

    .typeContainer {
        border-radius: 0.5rem;
    }

    .typeContainer p {
        /* font-size: 0.6rem; */
        font-size: 0.7rem;
        font-size: 0.9rem;
        /* font-size: 0.9rem; */
        /* font-size: clamp(0.6rem, 0.19999999999999984rem + 2.000000000000001vw, 0.8rem) !important; */
        font-size: clamp(0.7rem, 0.2999999999999998rem + 2.000000000000001vw, 0.9rem) !important;
        margin: 0px;
        padding: 0px;
    }

    .taskContainer {
        border-radius: 9px;
        border-radius: 0.5rem;
    }

    .taskContainer p {
        font-size: 0.7rem;
        font-size: 0.9rem;
        /* font-size: 0.8rem; */
        /* font-size: clamp(0.6rem, 0.19999999999999984rem + 2.000000000000001vw, 0.8rem) !important; */
        font-size: clamp(0.7rem, 0.2999999999999998rem + 2.000000000000001vw, 0.9rem) !important;
        margin: 0px;
        padding: 0px;
    }
}